import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Organisation } from '@models';

@Component({
  selector: 'app-dataset-form-organisation-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
  ],
})
export class DatasetFormOrganisationFormComponent {
  public organisationForm: FormGroup;
  public isEdit: boolean;

  constructor(
    public dialogRef: MatDialogRef<DatasetFormOrganisationFormComponent>,
    @Inject(MAT_DIALOG_DATA) organisation: Organisation,
  ) {
    this.isEdit = !!organisation;

    this.organisationForm = new FormGroup({
      name: new FormControl(organisation?.name, [Validators.required]),
      roles: new FormControl(organisation?.roles, [Validators.required]),
      email: new FormControl(organisation?.email, [Validators.email]),
      url: new FormControl(organisation?.url, [
        Validators.pattern(
          /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)$/,
        ),
      ]),
    });
  }

  public onSubmit() {
    if (this.organisationForm.pristine || !this.organisationForm.valid) {
      return;
    }

    const data = {
      name: this.organisationForm.value.name,
      roles: this.organisationForm.value.roles,
    } as Organisation;

    if (this.organisationForm.value.email) {
      data.email = this.organisationForm.value.email;
    }
    if (this.organisationForm.value.url) {
      data.url = this.organisationForm.value.url;
    }

    this.dialogRef.close(data);
  }

  public onCancel() {
    this.dialogRef.close();
  }
}
