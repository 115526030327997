@if (!isEdit) {
  <h2 mat-dialog-title>Add person</h2>
} @else {
  <h2 mat-dialog-title>Edit person</h2>
}

<mat-dialog-content>
  <form id="person-form" [formGroup]="personForm" (ngSubmit)="onSubmit()">
    <p>
      <mat-form-field appearance="outline">
        <mat-label>First name</mat-label>
        <input matInput formControlName="firstName" autocomplete="given-name" />
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="outline">
        <mat-label>Last name</mat-label>
        <input matInput formControlName="lastName" autocomplete="family-name" />
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="outline">
        <mat-label>Organisation</mat-label>
        <input matInput formControlName="organisation" />
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="outline">
        <mat-label>Roles</mat-label>
        <mat-select
          matInput
          formControlName="roles"
          multiple
          (selectionChange)="updateEmailValidators()">
          <mat-option value="author">Author</mat-option>
          <mat-option value="editor">Editor</mat-option>
          <mat-option value="pointOfContact">Point of contact</mat-option>
          <mat-option value="principalInvestigator">
            Principal investigator
          </mat-option>
          <mat-option value="processor">Processor</mat-option>
        </mat-select>
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <mat-hint>
          The email field is required when the contributor has the role “Point
          of Contact”
        </mat-hint>
        <input matInput formControlName="email" autocomplete="email" />
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="outline">
        <mat-label>ORCID</mat-label>
        <mat-hint
          >The 16-digit ORCID in the format 0000-0000-0000-0000</mat-hint
        >
        <input matInput formControlName="orcid" />
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="outline">
        <mat-label>URL</mat-label>
        <mat-hint
          >URLs must include the protocol (ex: https://example.com)</mat-hint
        >
        <input matInput formControlName="url" />
      </mat-form-field>
    </p>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-flat-button
    type="submit"
    form="person-form"
    [disabled]="personForm.pristine || !personForm.valid">
    Save
  </button>
  <button mat-button type="button" (click)="onCancel()"> Cancel </button>
</mat-dialog-actions>
