import { Component, inject, Inject, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { PrefixValidator, RequiredIfDirty } from '@validators';
import { Attachment } from '@models';
import { AttachmentService, NotificationService } from '@services';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'app-attachment-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss'],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatButtonModule,
    MatDatepickerModule,
    MatProgressBarModule,
  ],
})
export class AttachmentUpdateComponent {
  public form: FormGroup;

  attachmentService = inject(AttachmentService);
  notificationService = inject(NotificationService);

  isLoading = signal(false);

  constructor(
    public dialogRef: MatDialogRef<AttachmentUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public attachments: Attachment[],
  ) {
    if (attachments.length === 1) {
      const attachment = attachments[0];
      this.form = new FormGroup({
        filename: new FormControl(attachment.filename, [Validators.required]),
        prefix: new FormControl(attachment.prefix, [Validators.required, PrefixValidator]),
        title: new FormControl(attachment.title, []),
        description: new FormControl(attachment.description, []),
        releaseDate: new FormControl(attachment.released, []),
      });
    } else {
      let prefixValue = attachments[0].prefix;
      let releaseDateValue = attachments[0].released;
      for (const a of attachments) {
        if (a.prefix !== prefixValue) {
          prefixValue = "";
        }

        if (a.released !== releaseDateValue) {
          releaseDateValue = "";
        }
      }

      this.form = new FormGroup({
        prefix: new FormControl(prefixValue, [RequiredIfDirty, PrefixValidator]),
        releaseDate: new FormControl(releaseDateValue, []),
      });
    }
  }

  public async submit() {
    if (this.form.pristine || !this.form.valid) {
      return;
    }

    this.isLoading.set(true);

    const updatedAttachments = [];
    for (const attachment of this.attachments) {
      try {
        const updatedAttachment = await this.attachmentService
          .updateAttachment(attachment.datasetId, attachment.id, {
            filename: this.form.get('filename') ? this.form.get('filename')!.value : attachment.filename,
            title: this.form.get('title') ? this.form.get('title')!.value : attachment.title,
            description: this.form.get('description') ? this.form.get('description')!.value : attachment.description,
            prefix: this.attachments.length === 1 || this.form.get('prefix')!.dirty ? this.form.get('prefix')!.value : attachment.prefix,
            released: this.attachments.length === 1 || this.form.get('releaseDate')!.dirty ? this.form.get('releaseDate')!.value : attachment.released,
          } as Attachment);
        updatedAttachments.push(updatedAttachment);
      } catch (err) {
        console.error(err);
      }
    }

    if (updatedAttachments.length != this.attachments.length) {
      this.notificationService.error("Failed to update one or multiple files.");
    }

    this.dialogRef.close(updatedAttachments);
  }
}
