import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Dataset } from '@models';
import { TitleService } from '@services';
import { Observable, of, Subscription, switchMap } from 'rxjs';
import { PermissionTableComponent } from '../table/table.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-permission-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  imports: [
    CommonModule,
    PermissionTableComponent,
  ],
})
export class PermissionPageComponent implements OnInit, OnDestroy {
  public dataset$: Observable<Dataset | null>;

  private datasetSubscription: Subscription | null;

  constructor(
    private route: ActivatedRoute,
    private titleService: TitleService,
  ) {
    this.dataset$ = this.route.data.pipe(
      switchMap((data: Data) => {
        return of(data['dataset'] || null);
      }),
    );

    this.datasetSubscription = null;
  }

  ngOnInit() {
    this.datasetSubscription = this.dataset$.subscribe(
      (dataset: Dataset | null) => {
        if (dataset) {
          this.titleService.setTitle('Permissions', dataset.content.title);
        }

        return of(dataset);
      },
    );
  }

  ngOnDestroy(): void {
    this.datasetSubscription?.unsubscribe();
  }
}
