import { Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Timeframe } from '@models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dataset-form-timeframe-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  imports: [
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatInputModule,
  ],
})
export class DatasetFormTimeframeFormComponent implements OnDestroy {
  public timeframeForm: FormGroup;
  public isEdit: boolean;

  private isOngoingSubscription: Subscription;
  private endDateFormControl: FormControl;

  constructor(
    public dialogRef: MatDialogRef<DatasetFormTimeframeFormComponent>,
    private dateAdapter: DateAdapter<Date>,
    @Inject(MAT_DIALOG_DATA) timeframe: Timeframe,
  ) {
    const startDate = timeframe?.startDate
      ? new Date(timeframe.startDate)
      : null;
    const endDate = timeframe?.endDate ? new Date(timeframe.endDate) : null;

    this.isEdit = !!timeframe;
    this.endDateFormControl = new FormControl(endDate, [Validators.required]);
    this.timeframeForm = new FormGroup({
      isOngoing: new FormControl(this.isEdit ? !endDate : false),
      startDate: new FormControl(startDate, [Validators.required]),
    });

    if (!this.timeframeForm.controls['isOngoing'].value) {
      this.timeframeForm.addControl('endDate', this.endDateFormControl);
    }

    this.isOngoingSubscription = this.timeframeForm.controls[
      'isOngoing'
    ].valueChanges.subscribe((value: boolean) => {
      if (value) {
        this.timeframeForm.removeControl('endDate');
      } else {
        this.timeframeForm.addControl('endDate', this.endDateFormControl);
      }

      this.timeframeForm.updateValueAndValidity();
    });
  }

  ngOnDestroy() {
    this.isOngoingSubscription?.unsubscribe();
  }

  public onSubmit() {
    if (this.timeframeForm.pristine || !this.timeframeForm.valid) {
      return;
    }

    const startDate: Date = this.timeframeForm.value.startDate;
    const endDate: Date = this.timeframeForm.value.endDate;

    this.dialogRef.close({
      startDate: this.dateAdapter.format(startDate, {}) || null,
      endDate: this.dateAdapter.format(endDate, {}) || null,
    } as Timeframe);
  }

  public onCancel() {
    this.dialogRef.close();
  }
}
