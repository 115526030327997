import {
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
} from '@angular/common/http';
import { inject, } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@models';
import { AuthService, NotificationService } from '@services';
import { Observable, catchError, switchMap, take } from 'rxjs';

export function authorizationInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const authService = inject(AuthService);
  const notificationService = inject(NotificationService);
  const router = inject(Router);

  return authService.user$.pipe(
    take(1),
    switchMap((user: User | null) => {
      let authRequest: HttpRequest<unknown> = req;
      if (user !== null) {
        authRequest = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${user.token}`),
        });
      }

      return next(authRequest).pipe(
        catchError((err) => {
          if (err?.status === 401 && authService.isLoggedIn) {
            authService.logOut();
            notificationService.warning(
              'You have been logged out due to inactivity.',
            );
            router.navigate(['/']);
          }

          throw err;
        }),
      );
    }),
  );
}
