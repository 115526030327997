import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarModule,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  imports: [
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class SnackBarComponent {
  constructor(
    public ref: MatSnackBarRef<SnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      type: 'error' | 'warning' | 'success' | 'info';
      message: string;
    },
  ) { }
}
