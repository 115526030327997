<h1>User management</h1>

<p>
  <app-search [(ngModel)]="search" placeholder="users"> </app-search>
</p>

@if (selection.hasValue()) {
  <button mat-flat-button [disabled]="true">Edit</button>
  @if (
    selection.selected[0].status === 'active' ||
    selection.selected[0].status === 'pending'
  ) {
    @if (!isDirectoryUser()) {
      <button mat-flat-button (click)="pushRecovery()">Force Recovery</button>
    }
    <button mat-button (click)="updateStatus()">Disable</button>
  } @else {
    <button mat-flat-button (click)="updateStatus()">Enable</button>
  }
} @else {
  <button mat-flat-button (click)="openForm()">Add</button>
}

<table mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
    <td mat-cell *matCellDef="let element"> {{ element.email }} </td>
  </ng-container>
  <ng-container matColumnDef="accessLevel">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Access Level</th>
    <td mat-cell *matCellDef="let element">
      {{ element.accessLevel }}
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let element"> {{ element.status }} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator
  [pageSizeOptions]="[5, 20, 50]"
  showFirstLastButtons></mat-paginator>
