import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Person } from '@models';
import { ORCIDValidator } from '@validators';

@Component({
  selector: 'app-dataset-form-person-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule,
  ],
})
export class DatasetFormPersonFormComponent implements OnInit {
  public personForm: FormGroup;
  public isEdit: boolean;

  constructor(
    public dialogRef: MatDialogRef<DatasetFormPersonFormComponent>,
    @Inject(MAT_DIALOG_DATA) person: Person,
  ) {
    this.isEdit = !!person;

    this.personForm = new FormGroup({
      firstName: new FormControl(person?.firstName, [Validators.required]),
      lastName: new FormControl(person?.lastName, [Validators.required]),
      organisation: new FormControl(person?.organisation),
      roles: new FormControl(person?.roles || [], [Validators.required]),
      email: new FormControl(person?.email, [Validators.email]),
      orcid: new FormControl(person?.orcid, [
        Validators.pattern(/^(\d{4}-){3}\d{3}(\d|X)$/),
        ORCIDValidator,
      ]),
      url: new FormControl(person?.url, [
        Validators.pattern(
          /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)$/,
        ),
      ]),
    });
  }

  ngOnInit() {
    this.updateEmailValidators();
  }

  public updateEmailValidators() {
    const roles = this.personForm.controls['roles']?.value;
    const emailControl = this.personForm.controls['email'];
    if (roles.includes('pointOfContact')) {
      emailControl.addValidators(Validators.required);
    } else {
      emailControl.removeValidators(Validators.required);
    }
    emailControl.updateValueAndValidity();
  }

  public onSubmit() {
    if (this.personForm.pristine || !this.personForm.valid) {
      return;
    }

    const data = {
      firstName: this.personForm.value.firstName,
      lastName: this.personForm.value.lastName,
      roles: this.personForm.value.roles,
    } as Person;

    if (this.personForm.value.organisation) {
      data.organisation = this.personForm.value.organisation;
    }
    if (this.personForm.value.email) {
      data.email = this.personForm.value.email;
    }
    if (this.personForm.value.orcid) {
      data.orcid = this.personForm.value.orcid;
    }
    if (this.personForm.value.url) {
      data.url = this.personForm.value.url;
    }

    this.dialogRef.close(data);
  }

  public onCancel() {
    this.dialogRef.close();
  }
}
