import { Component } from '@angular/core';
import { environment } from '@environment';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent {
  public buildId: string;
  constructor() {
    this.buildId = environment.buildId;
  }
}
