@if (projections().length === 0) {
  <ng-container
    [ngTemplateOutlet]="leaflet"
    [ngTemplateOutletContext]="{ projection: 'EPSG3857' }">
  </ng-container>
} @else if (projections().length === 1) {
  <ng-container
    [ngTemplateOutlet]="leaflet"
    [ngTemplateOutletContext]="{ projection: projections()[0] }">
  </ng-container>
} @else {
  <mat-tab-group>
    @for (projection of projections(); track projection) {
      <mat-tab>
        <ng-template mat-tab-label>
          @if (featuresCount()[projection]; as count) {
            <b style="white-space: pre">({{ count }}) </b>
          }
          {{ projectionLabels.get(projection) }}
        </ng-template>
        <ng-template matTabContent>
          <ng-container
            [ngTemplateOutlet]="leaflet"
            [ngTemplateOutletContext]="{ projection: projection }">
          </ng-container>
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>
}

<ng-template #leaflet let-projection="projection">
  <app-leaflet
    #leafletComponentRef
    [height]="readonly() ? '300px' : '500px'"
    [projectionName]="projection"
    [geometry]="geometry()"
    [readonly]="readonly()"
    (geometryChange)="geometryChange.emit($event)"></app-leaflet>
</ng-template>
