import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { Dataset, Record, RecordListResponse } from '@models';
import { NotificationService, RecordService } from '@services';
import { BehaviorSubject, first, Observable, Subscription } from 'rxjs';
import { RecordItemComponent } from '../item/item.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-record-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatListModule,
    MatIconModule,
  ],
})
export class RecordListComponent implements OnInit, OnDestroy {
  @Input({ required: true })
  public dataset$!: Observable<Dataset>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public records$: BehaviorSubject<Record[]>;
  public isLoading$: BehaviorSubject<boolean>;

  private datasetId: string;
  private subscriptions: Subscription[];

  constructor(
    private recordService: RecordService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {
    this.records$ = new BehaviorSubject<Record[]>([]);
    this.isLoading$ = new BehaviorSubject<boolean>(false);
    this.subscriptions = [];
    this.datasetId = '';
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.dataset$.subscribe((dataset: Dataset) => {
        this.datasetId = dataset.id;
        this.loadPage({ pageIndex: 0, pageSize: 10 } as PageEvent, true);
      }),
    );
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s?.unsubscribe();
    }
  }

  public openRecord(record: Record): void {
    this.dialog.open(RecordItemComponent, {
      data: record,
      minWidth: '360px',
      width: '480px',
    });
  }

  public loadPage(event: PageEvent, doCount: boolean): void {
    this.isLoading$.next(true);
    this.recordService
      .getRecords(
        this.datasetId,
        '',
        event.pageIndex * event.pageSize,
        event.pageSize,
        doCount,
        null,
      )
      .pipe(first())
      .subscribe({
        next: (result: RecordListResponse) => {
          if (doCount && this.paginator) {
            this.paginator.firstPage();
            this.paginator.length = result.count;
          }

          this.records$.next(result.items);
        },
        error: (err) => {
          console.error(err);

          if (err?.status !== 401) {
            this.notificationService.error('Failed to load records.');
          }
        },
      })
      .add(() => {
        this.isLoading$.next(false);
      });
  }
}
