import {
  AfterViewInit,
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  input,
  OnDestroy,
  output,
  ViewChild,
} from '@angular/core';
import {
  FeatureCountControl,
  getProjection,
  ProjectionCode,
} from '@models/leaflet';
import { CursorPositionControl } from '@models/leaflet';
import { MediaService } from '@services';

import geojson from 'geojson';
import L from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import { MatDialog } from '@angular/material/dialog';
import { GeoJSONFormComponent } from './geojson-form/geojson-form.component';

L.Icon.Default.imagePath = 'assets/leaflet/images/';

const defaultProjection = ProjectionCode.Mercator;

@Component({
  selector: 'app-leaflet',
  templateUrl: './leaflet.component.html',
  styleUrls: ['./leaflet.component.scss'],
})
export class LeafletComponent implements AfterViewInit, OnDestroy {
  @ViewChild('mapElement')
  mapElementRef!: ElementRef<HTMLElement>;

  public id = new Date().getTime().toString();
  public height = input('300px');
  public readonly = input(true);
  public projectionName = input(defaultProjection);
  public geometry = input<geojson.FeatureCollection>();
  public geometryChange = output<geojson.FeatureCollection>();
  public layerCount = computed(() => this.drawLayer().getLayers().length);

  private mediaService = inject(MediaService);
  private dialog = inject(MatDialog);

  private featureCountControl = new FeatureCountControl();
  private cursorPositionControl = new CursorPositionControl({
    position: 'bottomright',
  });
  private map!: L.Map;

  private projection = computed(() =>
    getProjection(this.projectionName(), this.mediaService.theme()),
  );

  private tileLayer = computed(() => {
    const projection = this.projection();
    return L.tileLayer(projection.tilesetSource, {
      accessToken: 'tiles',
      tileSize: projection.tileSize,
      minZoom: projection.minZoom,
      maxZoom: projection.maxZoom,
      zoomOffset: projection.zoomOffset,
      noWrap: true,
      bounds: projection.tileBounds,
    });
  });

  private drawLayer = computed(() => {
    const geometry = this.geometry();

    let drawLayer: L.GeoJSON | null = null;
    this.map?.eachLayer((layer) => {
      if (layer instanceof L.GeoJSON) {
        drawLayer = layer;
      }
    });

    return (
      drawLayer ||
      L.geoJson(geometry, {
        filter: (feature) => {
          if (!feature.properties.projection) {
            feature.properties.projection = defaultProjection;
          }

          return feature.properties.projection === this.projectionName();
        },
      }).on('pm:edit', () => this.geometryChange.emit(this.featureCollection()))
    );
  });

  private featureCollection = computed(() => {
    const drawLayer = this.drawLayer();
    const collection = drawLayer.toGeoJSON() as geojson.FeatureCollection;

    for (const feature of collection.features) {
      feature.properties = { projection: this.projectionName() };
    }

    for (const feature of this.geometry()?.features || []) {
      if (!feature.properties) {
        feature.properties = { projection: defaultProjection };
      }

      if (feature.properties['projection'] !== this.projectionName()) {
        collection.features.push(feature);
      }
    }

    return collection;
  });

  constructor() {
    effect(() => {
      const tileLayer = this.tileLayer();
      this.map?.eachLayer((layer) => {
        if (layer instanceof L.TileLayer) {
          this.map.removeLayer(layer);
        }
      });

      this.map?.addLayer(tileLayer);
    });

    effect(() => {
      const geometry = this.geometry();
      this.featureCountControl.set(
        geometry?.features?.filter(
          (f) => f.properties?.['projection'] === this.projectionName(),
        ).length || 0,
      );
    });
  }

  ngAfterViewInit() {
    const projection = this.projection();
    this.map = L.map(this.mapElementRef.nativeElement.id, {
      crs: projection.crs,
      maxBounds: projection.viewBounds,
      minZoom: projection.minZoom,
      maxZoom: projection.maxZoom,
      zoom: projection.minZoom,
      center: projection.center,
      layers: [this.drawLayer(), this.tileLayer()],
      scrollWheelZoom: !this.readonly(),
      dragging: !L.Browser.mobile,
    });

    let center = projection.center;
    for (const layer of this.drawLayer().getLayers()) {
      if (layer instanceof L.Marker) {
        center = layer.getLatLng();
      } else if (layer instanceof L.Polyline || layer instanceof L.Circle) {
        center = layer.getBounds().getCenter();
      }
    }

    this.map.attributionControl.setPosition('bottomleft');
    this.map.addControl(this.cursorPositionControl);
    this.map.addControl(this.featureCountControl);

    // scroll wheel zoom while ctrl is held
    L.DomEvent.on(this.mapElementRef.nativeElement, 'mousewheel', (e) => {
      const event = e as WheelEvent;
      if (!event.ctrlKey) {
        return;
      }
      e.preventDefault(); // prevent page zoom
      if (event.deltaY < 0) {
        this.map.zoomIn();
      } else {
        this.map.zoomOut();
      }
    });

    if (!this.readonly()) {
      this.addDrawControls();
    }

    this.map.setView(center, projection.minZoom);
  }

  ngOnDestroy() {
    this.map?.off();
    this.map?.remove();

    this.mapElementRef?.nativeElement?.remove();
  }

  private addDrawControls() {
    this.map.pm.addControls({
      position: 'topleft',
      drawRectangle: false,
      drawCircleMarker: false,
      drawCircle: false,
      rotateMode: false,
      drawText: false,
    });

    this.map.pm.Toolbar.changeActionsOfControl('removalMode', [
      "cancel",
      {
        text: "Clear all",
        onClick: () => {
          this.drawLayer().clearLayers();
          this.geometryChange.emit(this.featureCollection());
        },
      },
    ]);

    this.map.pm.Toolbar.createCustomControl({
      block: 'custom',
      name: 'custom',
      title: 'Manual input',
      className: 'leaflet-pm-icon-text',
      actions: [
        {
          text: 'Add custom geometry',
          onClick: () => this.openGeoJsonDialog(),
        }
      ]
    });

    this.map.pm.setGlobalOptions({
      layerGroup: this.drawLayer(),
    });

    this.map.on('pm:create', () =>
      this.geometryChange.emit(this.featureCollection()),
    );
    this.map.on('pm:remove', () =>
      this.geometryChange.emit(this.featureCollection()),
    );
  }

  private openGeoJsonDialog() {
    const dialogRef = this.dialog.open(GeoJSONFormComponent, {
      minWidth: '360px',
      width: '480px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((polygon: geojson.Polygon | null) => {
      if (!polygon) {
        return;
      }

      this.drawLayer().addData({
        type: 'Feature',
        geometry: polygon,
        properties: {
          projection: this.projectionName(),
        },
      } as geojson.Feature<geojson.Geometry, geojson.GeoJsonProperties>);

      this.geometryChange.emit(this.featureCollection());
    });
  }
}
