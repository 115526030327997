<div class="npdc-title">
  <h1>Welcome to the Norwegian Polar Data Centre</h1>
  <div class="npdc-title__actions">
    @if (authService.user(); as user) {
      <button mat-flat-button (click)="openDatasetForm()">New dataset</button>
    }
  </div>
</div>

<form [formGroup]="searchForm">
  <p>
    <app-search [isLoading]="isLoading()" formControlName="search">
      @if (searchForm.contains('type')) {
        <button
          mat-icon-button
          [matMenuTriggerFor]="filterMenu"
          matTooltip="Advanced search"
          ><mat-icon>tune</mat-icon></button
        >
      } @else {
        <button
          mat-icon-button
          [matMenuTriggerFor]="locationFilterMenu"
          matTooltip="Advanced search"
          ><mat-icon>tune</mat-icon></button
        >
      }
    </app-search>
  </p>
</form>

@if (searchForm.value.location || searchForm.value.type) {
  <mat-chip-set>
    @if (searchForm.value.location; as value) {
      <mat-chip highlighted>
        <ng-container
          [ngTemplateOutlet]="optionTemplate"
          [ngTemplateOutletContext]="{ value: value }">
        </ng-container>
        <mat-icon matChipRemove (click)="applyLocationFilter(null)"
          >cancel</mat-icon
        >
      </mat-chip>
    }
    @if (searchForm.value.type; as value) {
      <mat-chip highlighted>
        <ng-container
          [ngTemplateOutlet]="optionTemplate"
          [ngTemplateOutletContext]="{ value: value }">
        </ng-container>
        <mat-icon matChipRemove (click)="applyTypeFilter(null)"
          >cancel</mat-icon
        >
      </mat-chip>
    }
  </mat-chip-set>
}

@for (dataset of datasets$ | async; track dataset.id; let isLast = $last) {
  <ng-container
    [ngTemplateOutlet]="datasetListItemTemplate"
    [ngTemplateOutletContext]="{ dataset: dataset, isLast: isLast }">
  </ng-container>
}

<mat-paginator
  [pageSizeOptions]="[defaultPageSize, 20, 50, 100, 200]"
  (page)="changePage($event)"
  showFirstLastButtons></mat-paginator>

<mat-menu #filterMenu="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="locationFilterMenu"
    >Location</button
  >
  <button mat-menu-item [matMenuTriggerFor]="statusFilterMenu">Status</button>
</mat-menu>

<mat-menu #locationFilterMenu="matMenu">
  @for (location of ['antarctica', 'arcticOcean', 'svalbard']; track location) {
    <button mat-menu-item (click)="applyLocationFilter(location)">
      <ng-container
        [ngTemplateOutlet]="optionTemplate"
        [ngTemplateOutletContext]="{ value: location }">
      </ng-container>
    </button>
  }
</mat-menu>

<mat-menu #statusFilterMenu="matMenu">
  @for (type of ['public', 'draft']; track type) {
    <button mat-menu-item (click)="applyTypeFilter(type)">
      <ng-container
        [ngTemplateOutlet]="optionTemplate"
        [ngTemplateOutletContext]="{ value: type }">
      </ng-container>
    </button>
  }
</mat-menu>

<ng-template #optionTemplate let-value="value">
  @switch (value) {
    @case ('antarctica') {
      Antarctica
    }
    @case ('arcticOcean') {
      Arctic Ocean
    }
    @case ('svalbard') {
      Svalbard
    }
    @case ('draft') {
      Draft
    }
    @case ('public') {
      Public
    }
    @default {
      {{ value }}
    }
  }
</ng-template>

<ng-template #datasetListItemTemplate let-dataset="dataset" let-isLast="isLast">
  <div>
    <h2>
      <a routerLink="/dataset/{{ dataset.id }}">{{ dataset.content.title }}</a>
    </h2>

    <app-dataset-citation
      [dataset]="dataset"
      type="short"></app-dataset-citation>

    <p>
      @if (dataset.type === 'public') {
        <strong class="doi-link"
          >https://doi.org/{{ dataset.doi | uppercase }}</strong
        >
      } @else {
        <mat-chip>Draft</mat-chip>
      }
    </p>

    @if (!isLast) {
      <mat-divider></mat-divider>
    }
  </div>
</ng-template>
