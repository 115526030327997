<h1>Password change</h1>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <p>
    <mat-form-field appearance="outline">
      <mat-label>New Password</mat-label>
      <input type="password" matInput formControlName="password" />
      <mat-icon matSuffix>password</mat-icon>
      @if (form.controls['password'].hasError('minlength')) {
        <mat-error>Must be at least 16 characters long</mat-error>
      } @else if (form.controls['password'].hasError('maxlength')) {
        <mat-error>Must be at most 255 characters long</mat-error>
      } @else if (form.controls['password'].hasError('pattern')) {
        <mat-error>Must start and end with printable characters</mat-error>
      }
    </mat-form-field>
  </p>

  <p>
    <mat-form-field appearance="outline">
      <mat-label>Confirm Password</mat-label>
      <input type="password" matInput formControlName="confirmPassword" />
      <mat-icon matSuffix>password</mat-icon>
      @if (form.controls['confirmPassword'].hasError('passwordMismatch')) {
        <mat-error>Passwords do not match</mat-error>
      }
    </mat-form-field>
  </p>

  <button
    mat-flat-button
    type="submit"
    [disabled]="form.pristine || !form.valid">
    Submit
  </button>
</form>
