<p>
  @if (!selection.hasValue()) {
    <button mat-flat-button (click)="openForm()">Add</button>
  } @else {
    @if (selection.selected.length === 1) {
      <button mat-flat-button (click)="openForm(selection.selected[0])"
        >Edit</button
      >
    }
    <button mat-button (click)="onDelete()">Delete</button>
  }
</p>

<mat-selection-list
  [multiple]="selection.isMultipleSelection()"
  (selectionChange)="onListSelectionChange($event)">
  @for (element of dataSource.data; track element; let isLast = $last) {
    <mat-list-option
      togglePosition="before"
      [value]="element"
      [selected]="selection.isSelected(element)">
      <span matListItemTitle>{{ element.name }}</span>
      <span matListItemLine
        ><b>Roles</b>: {{ element.roles | roleListString }}</span
      >
      @if (element.url) {
        <span matListItemLine>
          <b>URL</b>:
          <a
            appStopPropagation
            href="{{ element.url }}"
            target="_blank"
            rel="nofollow"
            >{{ element.url }}
          </a>
          @if (element.email) {
            <b>Email</b>:
            <a
              appStopPropagation
              href="mailto:{{ element.email }"
              target="_blank"
              >{{ element.email }}</a
            >
          }
        </span>
      }
    </mat-list-option>
    <!--since the maximum amount of list rows (3) is exceeded in this list, we have to insert a line break-->
    @if (isLast === false) {
      <br />
    }
  }
</mat-selection-list>
