<p>
  <app-search
    [(ngModel)]="search"
    [isLoading]="isLoading()"
    [placeholder]="currentDirectory() || 'all directories'">
    <button mat-icon-button matTooltip="Advanced search" (click)="openSearch()"
      ><mat-icon>tune</mat-icon></button
    >
  </app-search>
</p>

<p style="display: flex; justify-content: space-between">
  <span>
    <ng-container
      [ngTemplateOutlet]="breadcrumb"
      [ngTemplateOutletContext]="{ path: currentDirectory(), active: true }">
    </ng-container>
  </span>

  @if (attachmentCount() || subdirectories().length) {
    <a
      style="flex-shrink: 0"
      mat-stroked-button
      download
      href="{{ host }}/dataset/{{ dataset().id }}/attachment/_blob?{{
        queryParams()
      }}"
      matTooltip="Download selection as ZIP"
      [appAuthorize]="authService.user() !== null">
      <mat-icon>download</mat-icon>
      @if (q() || from() || until() || !currentDirectory()) {
        {{ attachmentCount() | compactNumber }} files
      } @else {
        @if (directories().get(currentDirectory()); as directory) {
          {{ directory.size | humanReadableSize }} -
          {{ directory.count | compactNumber }} files
        }
      }
    </a>
  }
</p>

<mat-chip-set>
  @if (!currentDirectory()) {
    <mat-chip highlighted>
      All directories
      <mat-icon matChipRemove (click)="currentDirectory.set('/')"
        >cancel</mat-icon
      >
    </mat-chip>
  }
  @if (from(); as value) {
    <mat-chip highlighted>
      From: {{ value | date: 'yyyy-MM-dd' }}
      <mat-icon matChipRemove (click)="from.set(null)">cancel</mat-icon>
    </mat-chip>
  }
  @if (until(); as value) {
    <mat-chip highlighted>
      Until: {{ value | date: 'yyyy-MM-dd' }}
      <mat-icon matChipRemove (click)="until.set(null)">cancel</mat-icon>
    </mat-chip>
  }
</mat-chip-set>

@if (subdirectories().length) {
  <mat-action-list>
    @for (path of subdirectories(); track path) {
      @if (directories().get(path); as subdirectory) {
        <button mat-list-item (click)="currentDirectory.set(path)">
          <mat-icon matListItemIcon>folder</mat-icon>
          <span matListItemTitle
            ><strong>{{ subdirectory.title }}</strong></span
          >
          <span matListItemLine
            ><strong>Files:</strong> {{ subdirectory.count }}</span
          >
          <span matListItemMeta>{{
            subdirectory.size | humanReadableSize
          }}</span>
        </button>
      }
    }
  </mat-action-list>

  @if (attachments().length) {
    <mat-divider></mat-divider>
  }
}

<mat-action-list>
  @for (attachment of attachments(); track attachment.id) {
    <button mat-list-item (click)="open(attachment)">
      <mat-icon matListItemIcon>
        @if (
          !attachment.permissions.mayRead ||
          !attachment.released ||
          attachment.released > currentDateString
        ) {
          lock
        } @else {
          description
        }
      </mat-icon>

      <span matListItemTitle>
        <ng-container
          [ngTemplateOutlet]="attachmentTitle"
          [ngTemplateOutletContext]="{ attachment: attachment }">
        </ng-container>
      </span>

      <span matListItemMeta>{{ attachment.byteSize | humanReadableSize }}</span>
    </button>
  }
</mat-action-list>

<mat-paginator
  showFirstLastButtons
  (page)="page.set($event)"
  [length]="attachmentCount()"
  [pageIndex]="page().pageIndex"
  [pageSize]="page().pageSize"
  [pageSizeOptions]="[10, 20, 50, 100]">
</mat-paginator>

<ng-template #breadcrumb let-path="path" let-active="active">
  @if (directories().get(path); as directory) {
    @if (directory.prev) {
      <ng-container
        [ngTemplateOutlet]="breadcrumb"
        [ngTemplateOutletContext]="{ path: directory.prev }">
      </ng-container>
      <span>></span>
    }

    <button
      class="npdc-breadcrumb"
      [class.npdc-breadcrumb__active]="active"
      [disabled]="active"
      mat-button
      (click)="currentDirectory.set(directory.path)">
      <h2>{{ directory.title === '/' ? 'Files' : directory.title }}</h2>
    </button>
  }
</ng-template>

<ng-template #attachmentTitle let-attachment="attachment">
  @if (attachment.permissions.mayRead) {
    <a
      target="_blank"
      href="{{ host }}/dataset/{{ attachment.datasetId }}/attachment/{{
        attachment.id
      }}/_blob"
      appStopPropagation
      [appAuthorize]="authorize(attachment)">
      <ng-container
        [ngTemplateOutlet]="title"
        [ngTemplateOutletContext]="{ attachment: attachment }">
      </ng-container>
    </a>
  } @else {
    <span>
      <ng-container
        [ngTemplateOutlet]="title"
        [ngTemplateOutletContext]="{ attachment: attachment }">
      </ng-container>
    </span>
  }
</ng-template>

<ng-template #title let-attachment="attachment"
  >{{ currentDirectory() === attachment.prefix ? '' : attachment.prefix
  }}{{ attachment.title || attachment.filename }}</ng-template
>
