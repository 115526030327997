<div class="npdc-outline markdown">
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="Markdown">
      <div class="tab-body">
        <mat-button-toggle-group (change)="$event.source.checked = false">
          <mat-button-toggle
            value="bold"
            matTooltip="Bold"
            (mousedown)="surroundSelection($event, '**', '**')"
            ><b>Bold</b></mat-button-toggle
          >
          <mat-button-toggle
            value="italic"
            matTooltip="Italic"
            (mousedown)="surroundSelection($event, '_', '_')"
            ><i>Italic</i></mat-button-toggle
          >
        </mat-button-toggle-group>

        <mat-button-toggle-group (change)="$event.source.checked = false">
          <mat-button-toggle
            value="h1"
            matTooltip="Big heading"
            (mousedown)="prependSelectedLines($event, '# ')"
            >H1</mat-button-toggle
          >
          <mat-button-toggle
            value="h2"
            matTooltip="Medium heading"
            (mousedown)="prependSelectedLines($event, '## ')"
            >H2</mat-button-toggle
          >
          <mat-button-toggle
            value="h3"
            matTooltip="Small heading"
            (mousedown)="prependSelectedLines($event, '### ')"
            >H3</mat-button-toggle
          >
        </mat-button-toggle-group>

        <mat-button-toggle-group (change)="$event.source.checked = false">
          <mat-button-toggle
            value="link"
            matTooltip="Link"
            (mousedown)="surroundSelection($event, '[', '](https://)')"
            ><mat-icon>link</mat-icon></mat-button-toggle
          >
          <mat-button-toggle
            value="image"
            matTooltip="Image"
            (mousedown)="surroundSelection($event, '![](', ')')"
            ><mat-icon>image</mat-icon></mat-button-toggle
          >
          <mat-button-toggle
            value="image"
            matTooltip="LaTeX"
            (mousedown)="
              surroundSelection($event, '`latex ', '`', '```latex\n', '\n```')
            "
            ><mat-icon>function</mat-icon></mat-button-toggle
          >
        </mat-button-toggle-group>

        <mat-button-toggle-group (change)="$event.source.checked = false">
          <mat-button-toggle
            value="ol"
            matTooltip="Ordered list"
            (mousedown)="prependSelectedLines($event, '1. ')"
            ><mat-icon>format_list_numbered</mat-icon></mat-button-toggle
          >
          <mat-button-toggle
            value="ul"
            matTooltip="Unordered list"
            (mousedown)="prependSelectedLines($event, '- ')"
            ><mat-icon>format_list_bulleted</mat-icon></mat-button-toggle
          >
        </mat-button-toggle-group>
        <p>
          <mat-form-field appearance="outline">
            <mat-label
              >{{ label }}
              @if (isRequired$ | async) {
                <span>*</span>
              }
            </mat-label>
            <textarea
              rows="8"
              matInput
              #textAreaElementRef
              [formControl]="textAreaFormControl"></textarea>
            <mat-hint
              >Use Markdown to create headlines, lists and more, see
              <a
                href="https://www.markdownguide.org/cheat-sheet/"
                target="_blank"
                >Markdown Cheat Sheet</a
              >. For LaTeX, define a
              <a
                href="https://www.markdownguide.org/basic-syntax/#code"
                target="_blank"
                >code</a
              >
              section with language <b>latex</b> (<a
                href="https://katex.org/docs/supported"
                target="_blank"
                >supported functions</a
              >).
            </mat-hint>
            @if (textAreaFormControl.hasError('required')) {
              <mat-error>This field is required.</mat-error>
            }
          </mat-form-field>
        </p>
      </div>
    </mat-tab>
    <mat-tab label="Preview">
      <div
        class="npdc-markdown tab-body"
        [innerHTML]="textAreaFormControl.value | markdown"></div>
    </mat-tab>
  </mat-tab-group>
</div>
