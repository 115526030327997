<p>
  @if (!selection.hasValue()) {
    <button mat-flat-button (click)="openForm()">Add</button>
  } @else {
    @if (selection.selected.length === 1) {
      <button mat-flat-button (click)="openForm(selection.selected[0])"
        >Edit</button
      >
    }
    <button mat-button (click)="onDelete()">Delete</button>
  }
</p>

<mat-selection-list
  [multiple]="selection.isMultipleSelection()"
  (selectionChange)="onListSelectionChange($event)">
  @for (element of dataSource.data; track element; let isLast = $last) {
    <mat-list-option
      togglePosition="before"
      [value]="element"
      [selected]="selection.isSelected(element)">
      <span matListItemTitle
        ><a
          appStopPropagation
          href="{{ element.href }}"
          target="_blank"
          rel="nofollow"
          >{{ element.title || element.href }}</a
        ></span
      >
      <span matListItemLine>{{ element.rel | titlecase }}</span>
    </mat-list-option>
  }
</mat-selection-list>
