<p>
  <mat-form-field appearance="outline">
    <mat-label>Title</mat-label>
    <input type="text" matInput [formControl]="title" />
    @if (title.hasError('required')) {
      <mat-error>This field is required.</mat-error>
    }
  </mat-form-field>
</p>

<p>
  <app-form-control-markdown label="Summary" [formControl]="summary" />
</p>

<app-form-control-autocomplete
  label="Keywords"
  [provider]="autocompleteProvider"
  [multiple]="true"
  [formControl]="keywords" />
