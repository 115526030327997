import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Event, NavigationEnd, Router, RouterModule } from '@angular/router';
import { SidenavComponent } from '@components/sidenav/sidenav.component';
import { MediaService, NotificationService } from '@services';
import { Subscription, filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
  imports: [
    RouterModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    SidenavComponent,
  ],
})
export class RootComponent implements OnDestroy {
  @ViewChild('sidenav') sidenav!: MatSidenav;

  private routerEventsSubscription: Subscription;

  constructor(
    public media: MediaService,
    router: Router,
    notificationService: NotificationService,
    iconRegistry: MatIconRegistry,
  ) {
    iconRegistry.setDefaultFontSetClass('material-symbols-rounded');

    this.routerEventsSubscription = router.events
      .pipe(
        filter((e: Event) => e instanceof NavigationEnd),
      )
      .subscribe(() => {
        notificationService.dismiss();

        if (!this.media.landscape()) {
          this.sidenav?.close();
        }
      });
  }

  ngOnDestroy(): void {
    this.routerEventsSubscription?.unsubscribe();
  }
}
