import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { EmailDomainValidator } from '@validators';

@Component({
  selector: 'app-attachment-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class UserFormComponent {
  public form: FormGroup;

  constructor(public dialogRef: MatDialogRef<UserFormComponent>) {
    this.form = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        EmailDomainValidator,
      ]),
    });
  }

  public onSubmit() {
    if (this.form.pristine || !this.form.valid) {
      return;
    }

    this.dialogRef.close(this.form.value.email);
  }

  public onCancel() {
    this.dialogRef.close(null);
  }
}
