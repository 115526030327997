import { bootstrapApplication } from '@angular/platform-browser';
import { RootComponent } from '@components/root/root.component';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { Routes } from '@angular/router';
import { NotFoundComponent } from '@components/404/404.component';
import { AboutComponent } from '@components/about/about.component';
import { AccountPasswordComponent } from '@components/account/password/password.component';
import { UserListComponent } from '@components/admin/user/list/list.component';
import { DatasetFormComponent } from '@components/dataset/form/form.component';
import { DatasetItemComponent } from '@components/dataset/item/item.component';
import { DatasetListComponent } from '@components/dataset/list/list.component';
import { PermissionPageComponent } from '@components/permission/page/page.component';
import { isAdminGuard, isLoggedInGuard, isNotLoggedInGuard } from '@guards';
import { datasetResolver } from '@resolvers';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authorizationInterceptor } from '@interceptors';
import { DateAdapter, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { ISODateAdapter } from './app/adapters/iso-date.adapter';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

declare global {
  interface Window {
    type: unknown | undefined;
  }
}

const routes: Routes = [
  { path: '', redirectTo: '/dataset', pathMatch: 'full' },
  { path: 'login', redirectTo: '/', pathMatch: 'full' },
  {
    path: 'admin',
    canActivate: [isAdminGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/admin/users',
      },
      {
        path: 'users',
        title: 'Users - NPDC',
        component: UserListComponent,
      },
    ],
  },
  {
    path: 'account',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/',
      },
      {
        path: 'password',
        title: 'Password - NPDC',
        canActivate: [isNotLoggedInGuard],
        component: AccountPasswordComponent,
      },
    ],
  },
  {
    path: 'dataset',
    title: 'Datasets - NPDC',
    children: [
      {
        path: '',
        component: DatasetListComponent,
      },
      {
        path: ':id',
        resolve: { dataset: datasetResolver },
        runGuardsAndResolvers: 'always',
        children: [
          {
            path: '',
            component: DatasetItemComponent,
          },
          {
            path: 'edit',
            component: DatasetFormComponent,
            canActivate: [isLoggedInGuard],
          },
          {
            path: 'permission',
            component: PermissionPageComponent,
            canActivate: [isLoggedInGuard],
          },
        ],
      },
    ],
  },
  {
    path: 'about',
    title: 'About - NPDC',
    component: AboutComponent,
  },
  {
    path: '**',
    title: '404 - NPDC',
    component: NotFoundComponent,
  },
];

bootstrapApplication(RootComponent, {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withInMemoryScrolling({
      scrollPositionRestoration: "top",
    })),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([authorizationInterceptor])),
    { provide: DateAdapter, useClass: ISODateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
      },
    },
  ]
} as ApplicationConfig)
  .catch((err) => console.error(err));
