import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject } from '@angular/core';
import { Record } from '@models';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '@services';
import { environment } from '@environment';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-record-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
  imports: [
    CommonModule,
    MatDialogModule,
    MatListModule,
    MatTabsModule,
    MatButtonModule,
  ],
})
export class RecordItemComponent {
  public url: string;

  constructor(
    private clipboard: Clipboard,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<RecordItemComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: Record,
  ) {
    this.url = `${environment.kinkoHost}/dataset/${data.datasetId}/record/?parentId=${data.id}&skip=0&take=5&order=created:desc`;
  }

  public copyToClipboard() {
    if (this.clipboard.copy(JSON.stringify(this.data))) {
      this.notificationService.success('Raw JSON copied to clipboard.');
    } else {
      this.notificationService.error('Clipboard operation failed.');
    }
  }
}
