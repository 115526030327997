import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Data, Router, RouterModule } from '@angular/router';
import {
  Dataset,
  FormState,
  User,
  ValidationIssue,
} from '@models';
import { AuthService, TitleService } from '@services';
import { BehaviorSubject, Observable, Subscription, map } from 'rxjs';
import { DatasetFormMetadataComponent } from './metadata/metadata.component';
import { DatasetFormOrganisationListComponent } from './organisation/list/list.component';
import { DatasetFormPersonListComponent } from './person/list/list.component';
import { DatasetFormTimeframeListComponent } from './timeframe/list/list.component';
import { DatasetFormAttachmentListComponent } from './attachment/list/list.component';
import { DatasetFormGeocoverageComponent } from './geocoverage/geocoverage.component';
import { DatasetFormLinkListComponent } from './link/list/list.component';
import { DatasetFormHarvestersComponent } from './harvesters/harvesters.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-dataset-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatTabsModule,
    MatIconModule,
    DatasetFormMetadataComponent,
    DatasetFormOrganisationListComponent,
    DatasetFormPersonListComponent,
    DatasetFormTimeframeListComponent,
    DatasetFormAttachmentListComponent,
    DatasetFormGeocoverageComponent,
    DatasetFormLinkListComponent,
    DatasetFormHarvestersComponent,
  ],
})
export class DatasetFormComponent implements OnInit, OnDestroy {
  public FormStateType = FormState;

  public validationIssues: ValidationIssue[];
  public dataset$: BehaviorSubject<Dataset>;
  public isAdmin$: Observable<boolean>;

  public formState: {
    title: BehaviorSubject<FormState>;
    summary: BehaviorSubject<FormState>;
    keywords: BehaviorSubject<FormState>;
    geojson: BehaviorSubject<FormState>;
    timeframes: BehaviorSubject<FormState>;
    contributors: BehaviorSubject<FormState>;
    organisations: BehaviorSubject<FormState>;
    links: BehaviorSubject<FormState>;
    harvesters: BehaviorSubject<FormState>;
    attachments: BehaviorSubject<FormState>;
  };

  private subscriptions: Subscription[];

  constructor(
    router: Router,
    private route: ActivatedRoute,
    private titleService: TitleService,
    private authService: AuthService,
  ) {
    this.validationIssues = [];
    this.formState = this.initFormState();
    this.subscriptions = [];

    const dataset = route.snapshot.data['dataset'];
    this.dataset$ = new BehaviorSubject<Dataset>(dataset);

    const navigation = router.getCurrentNavigation();
    if (navigation?.extras?.state?.['issues']) {
      this.validationIssues.push(...navigation.extras.state['issues']);
    }

    this.isAdmin$ = this.authService.user$.pipe(
      map((user: User | null) => user?.accessLevel === 'admin'),
    );
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.data.subscribe((data: Data) => {
        this.dataset$.next(data['dataset']);
      }),
    );

    this.subscriptions.push(
      this.dataset$.subscribe((dataset: Dataset) => {
        this.titleService.setTitle('Edit', dataset.content.title);

        // geojson tab content is lazy-loaded, so this check must happen here
        if (dataset.content.geojson?.features?.length) {
          this.formState.geojson.next(FormState.Valid);
        } else {
          this.formState.geojson.next(FormState.Invalid);
        }
      }),
    );

    for (const issue of this.validationIssues) {
      switch (issue.field) {
        case 'title':
          this.formState.title.next(FormState.Invalid);
          break;
        case 'summary':
          this.formState.summary.next(FormState.Invalid);
          break;
        case 'keywords':
          this.formState.keywords.next(FormState.Invalid);
          break;
        case 'geojson':
          this.formState.geojson.next(FormState.Invalid);
          break;
        case 'timeframes':
          this.formState.timeframes.next(FormState.Invalid);
          break;
        case 'contributors':
          this.formState.contributors.next(FormState.Invalid);
          break;
        case 'organisations':
          this.formState.organisations.next(FormState.Invalid);
          break;
        case 'links':
          this.formState.links.next(FormState.Invalid);
          break;
        case 'harvesters':
          this.formState.harvesters.next(FormState.Invalid);
          break;
        case 'attachments':
          this.formState.attachments.next(FormState.Invalid);
          break;
      }
    }
  }

  public combineStates(...states: (FormState | null)[]): FormState {
    let hasNone = false;
    for (const state of states) {
      if (state === FormState.Invalid) {
        return FormState.Invalid;
      } else if (state === FormState.None) {
        hasNone = true;
      }
    }

    return hasNone ? FormState.None : FormState.Valid;
  }

  private initFormState() {
    return {
      title: new BehaviorSubject<FormState>(FormState.None),
      summary: new BehaviorSubject<FormState>(FormState.None),
      keywords: new BehaviorSubject<FormState>(FormState.None),
      geojson: new BehaviorSubject<FormState>(FormState.None),
      timeframes: new BehaviorSubject<FormState>(FormState.None),
      contributors: new BehaviorSubject<FormState>(FormState.None),
      organisations: new BehaviorSubject<FormState>(FormState.None),
      links: new BehaviorSubject<FormState>(FormState.None),
      harvesters: new BehaviorSubject<FormState>(FormState.None),
      attachments: new BehaviorSubject<FormState>(FormState.None),
    };
  }
}
