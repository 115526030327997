<p>
  <input
    #fileInput
    type="file"
    title="blob"
    name="blob"
    placeholder="Select one or more files to upload"
    multiple
    hidden
    (change)="create($event)" />
  <input
    #dirInput
    type="file"
    title="blob"
    name="blob"
    placeholder="Select a directory to upload"
    webkitdirectory
    multiple
    hidden
    (change)="create($event)" />
</p>

@if (items.selectedOptions.selected.length; as length) {
  <button mat-flat-button (click)="update(items.selectedOptions.selected)"
    >Edit</button
  >
  @if (!disableDelete()) {
    <button mat-button (click)="delete(items.selectedOptions.selected)"
      >Delete</button
    >
  }
} @else {
  <button mat-flat-button (click)="fileInput.click()">Add</button>
  <button mat-button (click)="dirInput.click()">Add directory</button>
}

<mat-selection-list #items>
  @for (attachment of attachments(); track attachment.id) {
    <mat-list-option togglePosition="before" [value]="attachment.id">
      <span matListItemTitle>{{
        attachment.title || attachment.filename
      }}</span>

      <span matListItemLine>
        <strong>Prefix:</strong>
        {{ attachment.prefix }}
      </span>

      <span matListItemLine>
        @if (attachment.released) {
          <strong>Released:</strong>
          {{ attachment.released | date: 'yyyy-MM-dd' }}
        }
        <strong>Size:</strong>
        {{ attachment.byteSize | humanReadableSize }}
      </span>
    </mat-list-option>
  }
</mat-selection-list>

<mat-paginator
  showFirstLastButtons
  (page)="page.set($event)"
  [length]="attachmentCount()"
  [pageSize]="page().pageSize"
  [pageSizeOptions]="[10, 20, 50, 100]">
</mat-paginator>
