import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  effect,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { User, UserListResponse } from '@models';
import { NotificationService, UserService } from '@services';
import { UserFormComponent } from '../form/form.component';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { FormControlSearchComponent } from '@components/form-control/search/search.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  imports: [
    FormControlSearchComponent,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatIconModule,
    MatSortModule,
  ],
})
export class UserListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  search = signal('');

  public displayedColumns = ['select', 'email', 'accessLevel', 'status'];
  public dataSource: MatTableDataSource<User>;
  public selection: SelectionModel<User>;

  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {
    this.dataSource = new MatTableDataSource<User>([]);
    this.selection = new SelectionModel<User>(false, []);

    effect(() => {
      this.dataSource.filter = this.search();
    });
  }

  ngOnInit(): void {
    this.reloadData();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public openForm() {
    const dialogRef = this.dialog.open(UserFormComponent, {
      minWidth: '360px',
      width: '480px',
    });

    dialogRef.afterClosed().subscribe((email: string | null) => {
      if (!email) {
        return;
      }

      this.userService.createUser(email).subscribe({
        next: () => {
          this.reloadData();
          this.notificationService.success('User created successfully.');
        },
        error: (err) => {
          if (err?.status === 409) {
            this.notificationService.error('User already exists.');
          } else {
            console.error(err);
            this.notificationService.error('Failed to create user.');
          }
        },
      });
    });
  }

  public pushRecovery() {
    const user = this.selection?.selected[0] || null;

    if (!user) {
      this.notificationService.error('No user selected.');
      return;
    }

    if (user.status !== 'inactive') {
      this.userService.triggerAccountRecovery(user.email).subscribe({
        next: () => {
          this.reloadData();
          this.notificationService.success('Pushed recovery token to user.');
        },
        error: (err) => {
          if (err?.status === 404) {
            this.notificationService.error('User does not exist.');
          } else {
            console.error(err);
            this.notificationService.error('Failed to push recovery token.');
          }
        },
      });
    }
  }

  public updateStatus() {
    const user = this.selection?.selected[0] || null;

    if (!user) {
      this.notificationService.error('No user selected.');
      return;
    }

    let activate = false;
    if (user.status === 'inactive') {
      activate = true;
    }

    this.userService.updateUserStatus(user.id, activate).subscribe({
      next: () => {
        this.reloadData();
        if (activate) {
          this.notificationService.success('Account enabled successfully.');
        } else {
          this.notificationService.success('Account disabled successfully.');
        }
      },
      error: (err) => {
        if (err?.status === 404) {
          this.notificationService.error('User does not exist.');
        } else {
          console.error(err);
          this.notificationService.error('Failed to disable user.');
        }
      },
    });
  }

  public isDirectoryUser(): boolean {
    const user = this.selection?.selected[0] || null;

    if (user && user.email?.endsWith('@npolar.no')) {
      return true;
    }
    return false;
  }

  private reloadData(): void {
    this.userService.getUsers().subscribe({
      next: (result: UserListResponse) => {
        this.dataSource.data = result.accounts;
        this.selection?.clear();
      },
      error: (err) => {
        console.error(err);
        this.notificationService.error('Failed to load users.');
      },
    });
  }
}
