import { Component, effect, inject, input, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule, MatListOption, } from "@angular/material/list";
import { MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { Attachment, AttachmentListResponse, Dataset } from "@models";
import { AttachmentService, NotificationService } from "@services";
import { environment } from "@environment";
import { DatePipe } from "@angular/common";
import { HumanReadableSizePipe } from "@pipes";
import { MatButtonModule } from "@angular/material/button";
import { AttachmentCreateComponent } from "../form/create/create.component";
import { AttachmentUpdateComponent } from "../form/update/update.component";
import { AttachmentDeleteComponent } from "../form/delete/delete.component";

@Component({
  selector: 'app-dataset-form-attachment-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  imports: [
    MatListModule,
    MatPaginatorModule,
    MatIconModule,
    MatButtonModule,
    DatePipe,
    HumanReadableSizePipe,
  ],
})
export class DatasetFormAttachmentListComponent {
  dataset = input.required<Dataset>()
  disableDelete = input(false);

  host = environment.kinkoHost;
  attachments = signal<Attachment[]>([]);
  attachmentCount = signal(0);
  page = signal<PageEvent>({
    length: 0,
    pageIndex: 0,
    pageSize: 10,
  } as PageEvent);

  attachmentService = inject(AttachmentService);
  notificationService = inject(NotificationService);
  dialog = inject(MatDialog);

  constructor() {
    effect(() => {
      const page = this.page();
      this.attachmentService.getAttachments(
        this.dataset().id,
        page.pageIndex * page.pageSize,
        page.pageSize,
        page.length === 0,
        undefined, // filter params
        [{ column: 'created', direction: 'desc' }],
      ).subscribe({
        next: (result: AttachmentListResponse) => {
          if (result.count > 0) {
            this.attachmentCount.set(result.count);
          }

          this.attachments.set(result.items);
        },
        error: (err) => {
          console.error(err);
          this.notificationService.error("Failed to load files.");
        }
      });
    });
  }

  public create(event: Event) {
    const element = event.target as HTMLInputElement;
    const files: FileList | null = element.files;
    if (!files) {
      return;
    }

    const dialogRef = this.dialog.open(AttachmentCreateComponent, {
      data: {
        datasetId: this.dataset().id,
        files: files,
      },
      minWidth: '360px',
      width: '480px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(() => {
      element.value = '';
      this.page.update(p => ({ ...p, length: 0 }));
    });
  }

  public update(options: MatListOption[]) {
    const attachments = [];
    for (const option of options) {
      const attachment = this.attachments().find(a => a.id === option.value);
      if (attachment) {
        attachments.push(attachment);
      }
    }

    if (!attachments.length) {
      return;
    }

    const dialogRef = this.dialog.open(AttachmentUpdateComponent, {
      data: attachments,
      minWidth: '360px',
      width: '480px',
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .subscribe((updatedAttachments: Attachment[] | null) => {
        if (!updatedAttachments) {
          return;
        }

        this.attachments.update(attachments => {
          return attachments.map(a => updatedAttachments.find(ua => ua.id === a.id) || a);
        });
      });
  }

  public delete(options: MatListOption[]) {
    const ids = options.map(o => o.value as string);
    const attachments = this.attachments().filter(a => ids.includes(a.id));

    const dialogRef = this.dialog.open(AttachmentDeleteComponent, {
      data: attachments,
      minWidth: '360px',
      width: '480px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((deletedAttachments: Attachment[] | null) => {
      if (deletedAttachments) {
        this.page.update(p => ({ ...p, length: 0 }));
      }
    });
  }
}
