import { Component, inject, input, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router, Event, RouterModule } from '@angular/router';
import { LoginComponent } from '@components/login/login.component';
import { AuthService, DatasetService, MediaService, NotificationService } from '@services';
import { Subscription, filter, map } from 'rxjs';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  imports: [
    MatIconModule,
    MatListModule,
    MatButtonModule,
    RouterModule,
  ],
})
export class SidenavComponent implements OnInit {
  dialog = inject(MatDialog);
  datasetService = inject(DatasetService);
  notificationService = inject(NotificationService);
  authService = inject(AuthService);
  currentRoute = signal('/');
  isAccountMenuOpen = signal(false);
  media = inject(MediaService);

  sidenav = input.required<MatSidenav>();

  public isLinksExpanded: boolean;
  public isResourcesExpanded: boolean;

  private subscriptions: Subscription[];

  constructor(
    private router: Router,
  ) {
    this.subscriptions = [];
    this.isLinksExpanded = false;
    this.isResourcesExpanded = false;
  }

  ngOnInit() {
    const urlSubscription = this.router.events
      .pipe(
        filter((e: Event) => e instanceof NavigationEnd),
        map((e: Event) => {
          return (e as NavigationEnd).url;
        }),
      )
      .subscribe((url: string) => {
        this.currentRoute.set(url);
      });

    this.subscriptions.push(urlSubscription);
  }

  parseAccessLevel(accessLevel: string): string {
    switch (accessLevel) {
      case 'admin':
        return 'Administrator';
      case 'internal':
        return 'Internal';
      default:
        return 'External';
    }
  }

  logOut() {
    this.authService.logOut();
    this.router.navigateByUrl('/dataset');
  }

  openLoginDialog() {
    const dialogRef = this.dialog.open(LoginComponent, {
      minWidth: '360px',
      width: '480px',
    });

    dialogRef.afterClosed().subscribe((hasLoggedIn: boolean) => {
      if (!hasLoggedIn) {
        return;
      }

      this.router.navigateByUrl('/');
    });
  }
}
