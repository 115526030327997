import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable, OnDestroy, signal, } from '@angular/core';
import { map, Subscription } from 'rxjs';

export enum ThemeStyle {
  Light = 'light',
  Dark = 'dark',
}

@Injectable({
  providedIn: 'root',
})
export class MediaService implements OnDestroy {
  theme = signal(ThemeStyle.Light);
  landscape = signal(true);

  private subscription: Subscription;

  constructor(breakpointObserver: BreakpointObserver) {
    if (window.matchMedia) {
      const prefersDarkMediaQuery = window.matchMedia(
        '(prefers-color-scheme: dark)',
      );

      if (prefersDarkMediaQuery.matches) {
        this.theme.set(ThemeStyle.Dark);
      }

      prefersDarkMediaQuery.addEventListener('change', (event) => {
        if (event.matches) {
          this.theme.set(ThemeStyle.Dark);
        } else {
          this.theme.set(ThemeStyle.Light);
        }
      });
    }

    this.subscription = breakpointObserver
      .observe(['(min-width: 1200px)'])
      .pipe(map((result: BreakpointState) => result.matches)).subscribe(d => {
        this.landscape.set(d);
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
