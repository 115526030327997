import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormControlAutocompleteComponent } from '@components/form-control/autocomplete/autocomplete.component';
import {
  UserListResponse,
  AutocompleteOption,
  AutocompleteOptionProvider,
  Permission,
  User,
} from '@models';
import { PermissionService, UserService } from '@services';
import { map } from 'rxjs';

@Component({
  selector: 'app-permission-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  imports: [
    ReactiveFormsModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatButtonModule,
    FormControlAutocompleteComponent,
  ],
})
export class PermissionFormComponent {
  public permissionForm: FormGroup;
  public isEdit: boolean;

  public autocompleteProvider: AutocompleteOptionProvider<User>;

  constructor(
    public dialogRef: MatDialogRef<PermissionFormComponent>,
    private userService: UserService,
    private permissionService: PermissionService,
    @Inject(MAT_DIALOG_DATA) private permission: Permission,
  ) {
    this.isEdit = !!permission;

    this.autocompleteProvider = (term: string) => {
      return this.userService.getUsers(term).pipe(
        map((response: UserListResponse) => {
          if (!response?.accounts?.length) {
            return [];
          }

          return response.accounts.map((user: User) => {
            return {
              id: user.email,
              displayValue: user.email,
              value: user,
            } as AutocompleteOption<User>;
          });
        }),
      );
    };

    const defaultRole = permissionService.getRoleFromPermissions(permission);
    this.permissionForm = new FormGroup({
      role: new FormControl(defaultRole, [Validators.required]),
    });

    if (!this.isEdit) {
      this.permissionForm.addControl(
        'user',
        new FormControl([], [Validators.required]),
      );
    }
  }

  public onSubmit() {
    if (this.permissionForm.pristine || !this.permissionForm.valid) {
      return;
    }

    const formValues = this.permissionForm.value;

    const permissions = this.permissionService.getPermissionsFromRole(
      formValues.role,
    );

    const userId = this.isEdit
      ? this.permission.userId
      : formValues.user[0].value.id;

    this.dialogRef.close({
      userId: userId,
      mayRead: permissions.mayRead,
      mayUpdate: permissions.mayUpdate,
      mayDelete: permissions.mayDelete,
    } as Permission);
  }

  public onCancel() {
    this.dialogRef.close();
  }
}
