import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Dataset,
  DatasetContent,
  DatasetListResponse,
  Organisation,
} from '@models';
import { Observable } from 'rxjs';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class DatasetService {
  // these polygons operate on a PostGIS geography and may not contain edges longer than 180 deg
  private polygons = new Map<string, string>([
    [
      'arcticOcean',
      'POLYGON((0 59,20 59,40 59,60 59,80 59,100 59,120 59,140 59,160 59,180 59,200 59,220 59,240 59,260 59,280 59,300 59,320 59,340 59,0 59),(-10.0 60.1,-75.0 60.1,-75.0 84.0,-10.0 84.0,-10.0 60.1))',
    ],
    [
      'antarctica',
      'POLYGON((0 -59,20 -59,40 -59,60 -59,80 -59,100 -59,120 -59,140 -59,160 -59,180 -59,200 -59,220 -59,240 -59,260 -59,280 -59,300 -59,320 -59,340 -59,0 -59))',
    ],
    [
      'svalbard',
      'POLYGON((34.0 74.2,34.0 80.5,10.0 80.5,10.0 74.2,34.0 74.2))',
    ],
  ]);

  constructor(private http: HttpClient) { }

  createDataset(title: string) {
    const endpoint = `${environment.kinkoHost}/dataset/`;

    return this.http.post<Dataset>(
      endpoint,
      {
        title: title,
        organisations: [
          {
            name: 'Norwegian Polar Institute',
            roles: [
              'owner',
              'pointOfContact',
              'principalInvestigator',
              'resourceProvider',
            ],
            email: 'data@npolar.no',
            url: 'https://npolar.no',
          },
        ] as Organisation[],
      } as DatasetContent,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      },
    );
  }

  publishDataset(id: string, content: DatasetContent) {
    const endpoint = `${environment.kinkoHost}/dataset/${id}`;
    const params = new HttpParams().set('publish', 'public');

    return this.http.put<Dataset>(endpoint, content, {
      params: params,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  getDatasets(
    skip: number,
    take: number,
    count: boolean,
    filter?: {
      search: string;
      location?: string;
      type?: string;
    },
    order?: { column: string; direction: 'asc' | 'desc' }[],
  ): Observable<DatasetListResponse> {
    const endpoint = `${environment.kinkoHost}/dataset/`;
    let params = new HttpParams().set('skip', skip).set('take', take);

    if (count) {
      params = params.set('count', count);
    }

    if (filter) {
      if (filter.search) {
        params = params.set('q', filter.search);
      }

      if (filter.type) {
        params = params.set('type', filter.type);
      }

      if (filter.location) {
        const polygon = this.polygons.get(filter.location);
        if (polygon) {
          params = params.set('location', polygon);
        }
      }
    }

    for (const o of order || []) {
      params = params.append('order', `${o.column}:${o.direction}`);
    }

    return this.http.get<DatasetListResponse>(endpoint, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: params,
    });
  }

  getDataset(id: string): Observable<Dataset> {
    const endpoint = `${environment.kinkoHost}/dataset/${id}`;

    return this.http.get<Dataset>(endpoint, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  updateDataset(id: string, content: DatasetContent) {
    const endpoint = `${environment.kinkoHost}/dataset/${id}`;

    return this.http.put<Dataset>(endpoint, content, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  deleteDataset(id: string) {
    const endpoint = `${environment.kinkoHost}/dataset/${id}`;

    return this.http.delete(endpoint, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }
}
