import { Component, Input } from '@angular/core';
import { Dataset, DatasetContent, FormState } from '@models';
import { DatasetService, NotificationService } from '@services';
import { BehaviorSubject } from 'rxjs';
import geojson from 'geojson';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { LeafletGroupComponent } from '@components/leaflet/group/group.component';

@Component({
  selector: 'app-dataset-form-geocoverage',
  templateUrl: './geocoverage.component.html',
  styleUrls: ['./geocoverage.component.scss'],
  imports: [
    MatCardModule,
    MatIconModule,
    LeafletGroupComponent,
  ],
})
export class DatasetFormGeocoverageComponent {
  @Input({ required: true })
  dataset!: Dataset;

  @Input({ required: true })
  formState!: BehaviorSubject<FormState>;

  public tabIndex = 0;

  constructor(
    private datasetService: DatasetService,
    private notificationService: NotificationService,
  ) { }

  onChange(geometry: geojson.FeatureCollection) {
    const datasetContent = {
      ...this.dataset.content,
    };

    if (!geometry?.features.length) {
      delete datasetContent.geojson;
    } else {
      datasetContent.geojson = geometry;
    }

    this.updateDataset(datasetContent);
  }

  private updateDataset(datasetContent: DatasetContent) {
    this.datasetService
      .updateDataset(this.dataset.id, datasetContent)
      .subscribe({
        next: () => {
          this.dataset.content = datasetContent;
          this.updateValidity();
        },
        error: (err) => {
          console.error(err);

          const issues: null | { type: string }[] =
            err?.error?.details?.issues || null;

          if (issues !== null && issues.length) {
            for (const issue of issues) {
              switch (issue.type) {
                case 'InvalidGeoJSON': {
                  this.notificationService.error('Invalid geometry.');
                  break;
                }
                default: {
                  this.notificationService.error('Failed to save changes.');
                  break;
                }
              }
            }
          } else {
            this.notificationService.error('Failed to save changes.');
          }
        },
      });
  }

  private updateValidity() {
    if (this.dataset.content.geojson?.features.length) {
      this.formState.next(FormState.Valid);
    } else {
      this.formState.next(FormState.Invalid);
    }
  }
}
