@if (attachments.length === 1) {
  <h2 mat-dialog-title>Edit file</h2>
} @else {
  <h2 mat-dialog-title>Edit multiple files</h2>
}

<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content>
    @if (isLoading()) {
      <p>Files are being updated. Please wait...</p>
    } @else {
      @if (form.get('filename')) {
        <p>
          <mat-form-field appearance="outline">
            <mat-label>Filename</mat-label>
            <input type="text" matInput formControlName="filename" />
          </mat-form-field>
        </p>
      }

      <p>
        <mat-form-field appearance="outline">
          <mat-label>Prefix</mat-label>
          <input type="text" matInput formControlName="prefix" />
          @if (form.controls['prefix'].hasError('leadingslash')) {
            <mat-error>Prefix must start with '/'</mat-error>
          } @else if (form.controls['prefix'].hasError('trailingslash')) {
            <mat-error>Prefix must end with '/'</mat-error>
          }
          @if (attachments.length > 1 && !form.get('prefix')?.dirty) {
            <mat-hint>Prefix will remain unchanged.</mat-hint>
          }
        </mat-form-field>
      </p>

      @if (form.get('title')) {
        <p>
          <mat-form-field appearance="outline">
            <mat-label>Title</mat-label>
            <input type="text" matInput formControlName="title" />
          </mat-form-field>
        </p>
      }

      @if (form.get('description')) {
        <p>
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea
              rows="4"
              matInput
              formControlName="description"></textarea>
          </mat-form-field>
        </p>
      }

      <p>
        <mat-form-field appearance="outline">
          <mat-label>Release date</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="releaseDate" />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          @if (attachments.length > 1 && !form.get('releaseDate')?.dirty) {
            <mat-hint>Release date will remain unchanged.</mat-hint>
          } @else {
            <mat-hint>yyyy-MM-DD</mat-hint>
          }
        </mat-form-field>
      </p>
    }
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    @if (isLoading()) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    } @else {
      <button
        mat-flat-button
        type="submit"
        [disabled]="form.pristine || !form.valid">
        Save
      </button>
      <button mat-button type="button" mat-dialog-close> Cancel </button>
    }
  </mat-dialog-actions>
</form>
