@if (!media.landscape()) {
  <mat-toolbar>
    <button
      mat-icon-button
      matTooltip="Toggle navigation drawer"
      (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>

    <a mat-button routerLink="/">Norwegian Polar Data Centre</a>
  </mat-toolbar>
}
<mat-sidenav-container>
  <mat-sidenav
    [fixedInViewport]="true"
    #sidenav
    [disableClose]="media.landscape()"
    [mode]="media.landscape() ? 'side' : 'over'"
    [opened]="media.landscape() ? true : false">
    <app-sidenav [sidenav]="sidenav"></app-sidenav>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="router-outlet-container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
