@if (dataset$ | async; as dataset) {
  <div class="npdc-title">
    <h1>{{ dataset.content.title }}</h1>

    @if (dataset.permissions?.mayUpdate) {
      <div class="npdc-title__actions">
        @if (dataset.type === 'draft') {
          <button mat-flat-button (click)="publishDataset(dataset)"
            >Publish</button
          >
          <button mat-button [routerLink]="'edit'">Edit</button>
        } @else {
          <button mat-flat-button [routerLink]="'edit'">Edit</button>
        }
        <button
          mat-icon-button
          [matMenuTriggerFor]="optionsMenu"
          matTooltip="More actions">
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
    }
  </div>

  <mat-tab-group animationDuration="0ms" dynamicHeight disablePagination="true">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>article_outlined</mat-icon>
        Metadata
      </ng-template>

      <ng-template matTabContent>
        <app-dataset-license [dataset]="dataset"></app-dataset-license>

        @if (dataset.type === 'public') {
          <mat-card appearance="outlined">
            <mat-card-content>
              <app-dataset-citation
                #citation
                [dataset]="dataset"></app-dataset-citation>
            </mat-card-content>
            <mat-card-actions align="end">
              <a
                mat-button
                target="_blank"
                rel="nofollow noopener noreferrer"
                href="https://citation.doi.org/format?doi={{
                  dataset.doi
                }}&style=bibtex&lang=en-GB"
                ><mat-icon>open_in_new</mat-icon>BibTeX</a
              >
              <button mat-button (click)="copyToClipboard(citation.apa())"
                >Copy to clipboard</button
              >
            </mat-card-actions>
          </mat-card>
        }

        @if (dataset.content.geojson; as geometry) {
          <h2>Geographical coverage</h2>
          <mat-card appearance="outlined" class="npdc-leaflet">
            <app-leaflet-group [geometry]="geometry"></app-leaflet-group>
          </mat-card>
        }

        <h2>Summary</h2>
        <div
          class="npdc-markdown"
          [innerHtml]="dataset.content.summary | markdown"></div>

        <ng-container [ngTemplateOutlet]="datasetDetails"> </ng-container>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>database</mat-icon>
        Files
      </ng-template>

      <ng-template matTabContent>
        <app-attachment-list [dataset]="dataset"></app-attachment-list>
      </ng-template>
    </mat-tab>

    @if ((hasRecords$ | async) === true) {
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>account_tree</mat-icon>
          Records
        </ng-template>

        <ng-template matTabContent>
          <app-record-list [dataset$]="dataset$"></app-record-list>
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>

  <ng-template #datasetDetails>
    <!-- links -->
    @if (dataset.content.links?.length) {
      <h2>Links</h2>
      <mat-list>
        @for (link of dataset.content.links; track link) {
          <mat-list-item>
            <mat-icon matListItemIcon>link</mat-icon>
            <span matListItemTitle>
              <a href="{{ link.href }}" target="_blank">{{
                link.title || link.href
              }}</a>
              ({{ link.rel | titlecase }})</span
            >
          </mat-list-item>
        }
      </mat-list>
    }

    <!-- keywords -->
    @if (dataset.content.keywords?.length) {
      <h2>Keywords</h2>
      <mat-list>
        @for (keyword of dataset.content.keywords; track keyword) {
          <mat-list-item>
            <mat-icon matListItemIcon>label</mat-icon>
            <div matListItemTitle>
              @if (keyword.category) {
                <span>{{ keyword.category }}</span>
              }
              @if (keyword.topic) {
                <span> > {{ keyword.topic }}</span>
              }
              @if (keyword.term) {
                <span> > {{ keyword.term }}</span>
              }
              @if (keyword.variableLevel1) {
                <span> > {{ keyword.variableLevel1 }}</span>
              }
              @if (keyword.variableLevel2) {
                <span> > {{ keyword.variableLevel2 }}</span>
              }
              @if (keyword.variableLevel3) {
                <span> > {{ keyword.variableLevel3 }}</span>
              }
            </div>
          </mat-list-item>
        }
      </mat-list>
    }

    <!-- timeframes -->
    @if (dataset.content.timeframes?.length) {
      <h2>Timeframes</h2>
      <mat-list>
        @for (timeframe of dataset.content.timeframes; track timeframe) {
          <mat-list-item>
            <mat-icon matListItemIcon>timeline</mat-icon>
            <div matListItemTitle>
              <b>Start</b>: {{ timeframe.startDate }}
            </div>
            @if (timeframe.endDate) {
              <div matListItemLine> <b>End</b>: {{ timeframe.endDate }} </div>
            }
          </mat-list-item>
        }
      </mat-list>
    }

    <!-- contributors -->
    @if (dataset.content.people?.length) {
      <h2>Contributors</h2>
      <mat-list>
        @for (person of dataset.content.people; track person) {
          <mat-list-item>
            <mat-icon matListItemIcon>person</mat-icon>
            <div matListItemTitle
              >{{ person.firstName }} {{ person.lastName }}</div
            >
            @if (person.email) {
              <div matListItemLine>{{ person.email }}</div>
            }
            <div matListItemLine>
              @for (role of person.roles; track role; let last = $last) {
                @switch (role) {
                  @case ('author') {
                    <span>Author</span>
                  }
                  @case ('editor') {
                    <span>Editor</span>
                  }
                  @case ('pointOfContact') {
                    <span>Point of Contact</span>
                  }
                  @case ('principalInvestigator') {
                    <span>Principal Investigator</span>
                  }
                  @case ('processor') {
                    <span>Processor</span>
                  }
                  @default {
                    <span>{{ role }}</span>
                  }
                }
                @if (!last) {
                  <span>, </span>
                }
              }
            </div>
            <div matListItemMeta>
              <button
                mat-icon-button
                matTooltip="Actions"
                [matMenuTriggerFor]="contributorActionsMenu"
                [matMenuTriggerData]="{ person: person }">
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
          </mat-list-item>
        }
      </mat-list>
    }

    <!-- organisations -->
    @if (dataset.content.organisations?.length) {
      <h2>Organisations</h2>
      <mat-list>
        @for (
          organisation of dataset.content.organisations;
          track organisation
        ) {
          <mat-list-item>
            <mat-icon matListItemIcon>group</mat-icon>
            <div matListItemTitle>{{ organisation.name }}</div>
            @if (organisation.email) {
              <div matListItemLine>{{ organisation.email }}</div>
            }
            <div matListItemLine>
              @for (role of organisation.roles; track role; let last = $last) {
                @switch (role) {
                  @case ('author') {
                    <span>Author</span>
                  }
                  @case ('originator') {
                    <span>Originator</span>
                  }
                  @case ('owner') {
                    <span>Owner</span>
                  }
                  @case ('pointOfContact') {
                    <span>Point of Contact</span>
                  }
                  @case ('principalInvestigator') {
                    <span>Principal Investigator</span>
                  }
                  @case ('processor') {
                    <span>Processor</span>
                  }
                  @case ('resourceProvider') {
                    <span>Resource Provider</span>
                  }
                  @default {
                    <span>{{ role }}</span>
                  }
                }
                @if (!last) {
                  <span>, </span>
                }
              }
            </div>
            <div matListItemMeta>
              <button
                mat-icon-button
                matTooltip="Actions"
                [matMenuTriggerFor]="organisationActionsMenu"
                [matMenuTriggerData]="{ organisation: organisation }">
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
          </mat-list-item>
        }
      </mat-list>
    }

    <!-- metadata -->
    <h2>Other</h2>
    <ng-container [ngTemplateOutlet]="datasetMetadata"> </ng-container>
  </ng-template>

  <ng-template #datasetMetadata>
    <mat-list>
      <mat-list-item>
        <div matListItemTitle>DOI</div>
        <div matListItemMeta>
          @if (dataset.type === 'draft') {
            <mat-chip style="display: inline-flex !important"
              >Inactive</mat-chip
            >
          }
        </div>
        <div matListItemLine>
          <a href="https://doi.org/{{ dataset.doi }}" target="_blank">{{
            dataset.doi
          }}</a>
        </div>
        @if (dataset.type === 'draft') {
          <div matListItemLine
            >This DOI will be assigned to the dataset when published.</div
          >
        }
      </mat-list-item>
      @if (dataset.type !== 'draft') {
        <mat-list-item>
          <div matListItemTitle>Published</div>
          <div matListItemLine
            >{{ dataset.published | date: 'yyyy-MM-dd' }} by
            @if (dataset.publishedBy | resolveUser | async; as user) {
              <a href="mailto:{{ user.email }}">{{ user.email }}</a>
            } @else {
              {{ dataset.publishedBy }}
            }
          </div>
        </mat-list-item>
      }
      <mat-list-item>
        <div matListItemTitle>Created</div>
        <div matListItemLine
          >{{ dataset.created | date: 'yyyy-MM-dd' }} by
          @if (dataset.createdBy | resolveUser | async; as user) {
            <a href="mailto:{{ user.email }}">{{ user.email }}</a>
          } @else {
            {{ dataset.createdBy }}
          }
        </div>
      </mat-list-item>
      <mat-list-item>
        <div matListItemTitle>Modified</div>
        <div matListItemLine
          >{{ dataset.modified | date: 'yyyy-MM-dd' }} by
          @if (dataset.modifiedBy | resolveUser | async; as user) {
            <a href="mailto:{{ user.email }}">{{ user.email }}</a>
          } @else {
            {{ dataset.modifiedBy }}
          }
        </div>
      </mat-list-item>
    </mat-list>
  </ng-template>

  <mat-menu #contributorActionsMenu>
    <ng-template matMenuContent let-person="person">
      @if (person.url) {
        <a mat-menu-item href="{{ person.url }}" target="_blank"
          >Go to website</a
        >
      }
      @if (person.orcid) {
        <a
          mat-menu-item
          href="https://orcid.org/{{ person.orcid }}"
          target="_blank"
          >Go to ORCID page</a
        >
      }
      @if (person.email) {
        <a mat-menu-item href="mailto:{{ person.email }}" target="_blank"
          >Send email</a
        >
      }
      <a
        mat-menu-item
        [routerLink]="['/dataset']"
        [queryParams]="{ q: person.firstName + ' ' + person.lastName }"
        >Show datasets from this person</a
      >
    </ng-template>
  </mat-menu>
  <mat-menu #organisationActionsMenu>
    <ng-template matMenuContent let-organisation="organisation">
      @if (organisation.url) {
        <a mat-menu-item href="{{ organisation.url }}" target="_blank">
          Go to website
        </a>
      }
      @if (organisation.email) {
        <a mat-menu-item href="mailto:{{ organisation.email }}" target="_blank">
          Send email
        </a>
      }
      <a
        mat-menu-item
        [routerLink]="['/dataset']"
        [queryParams]="{ q: organisation.name }">
        Show datasets from this organisation
      </a>
    </ng-template>
  </mat-menu>
  <mat-menu #optionsMenu>
    <button mat-menu-item [routerLink]="['/dataset', dataset.id, 'permission']"
      >Manage permissions</button
    >
    @if (dataset.type === 'draft') {
      <button mat-menu-item (click)="deleteDataset(dataset.id)">Delete</button>
    }
  </mat-menu>
}

<ng-template #publishPromptTemplate>
  <p>This action will</p>

  <ul>
    <li>Activate the reserved DOI.</li>
    <li>Make the dataset publicly visible (no login required).</li>
    <li>Lock attached files in place (they can no longer be removed).</li>
  </ul>
</ng-template>
