@if (dataset$ | async; as dataset) {
  <div class="npdc-title">
    <h1>Edit - {{ dataset.content.title }}</h1>

    <p class="npdc-title__actions">
      <button mat-flat-button [routerLink]="['/dataset', dataset.id]"
        >Close</button
      >
    </p>
  </div>

  <mat-tab-group
    #tabGroup
    animationDuration="0ms"
    disablePagination="true"
    dynamicHeight>
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-container
          [ngTemplateOutlet]="tabIcon"
          [ngTemplateOutletContext]="{
            type: combineStates(
              formState.title | async,
              formState.summary | async,
              formState.keywords | async
            ),
            default: 'info',
          }">
        </ng-container>
        General
      </ng-template>
      <app-dataset-form-metadata
        [dataset]="dataset"
        [titleFormState]="formState.title"
        [summaryFormState]="formState.summary"
        [keywordsFormState]="formState.keywords">
      </app-dataset-form-metadata>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-container
          [ngTemplateOutlet]="tabIcon"
          [ngTemplateOutletContext]="{
            type: (formState.geojson | async),
            default: 'public',
          }">
        </ng-container>
        Geographical Coverage
      </ng-template>
      <ng-template matTabContent>
        <app-dataset-form-geocoverage
          [dataset]="dataset"
          [formState]="formState.geojson">
        </app-dataset-form-geocoverage>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-container
          [ngTemplateOutlet]="tabIcon"
          [ngTemplateOutletContext]="{
            type: (formState.timeframes | async),
            default: 'schedule',
          }">
        </ng-container>
        Timeframes
      </ng-template>
      <app-dataset-form-timeframe-list
        [dataset]="dataset"
        [formState]="formState.timeframes">
      </app-dataset-form-timeframe-list>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-container
          [ngTemplateOutlet]="tabIcon"
          [ngTemplateOutletContext]="{
            type: (formState.contributors | async),
            default: 'person',
          }">
        </ng-container>
        Contributors
      </ng-template>
      <app-dataset-form-person-list
        [dataset]="dataset"
        [formState]="formState.contributors">
      </app-dataset-form-person-list>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-container
          [ngTemplateOutlet]="tabIcon"
          [ngTemplateOutletContext]="{
            type: (formState.organisations | async),
            default: 'business',
          }">
        </ng-container>
        Organisations
      </ng-template>
      <app-dataset-form-organisation-list
        [dataset]="dataset"
        [formState]="formState.organisations">
      </app-dataset-form-organisation-list>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-container
          [ngTemplateOutlet]="tabIcon"
          [ngTemplateOutletContext]="{
            type: (formState.links | async),
            default: 'link',
          }">
        </ng-container>
        Links
      </ng-template>
      <app-dataset-form-link-list
        [dataset]="dataset"
        [formState]="formState.links">
      </app-dataset-form-link-list>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-container
          [ngTemplateOutlet]="tabIcon"
          [ngTemplateOutletContext]="{
            type: (formState.harvesters | async),
            default: 'present_to_all',
          }">
        </ng-container>
        Harvesters
      </ng-template>
      <app-dataset-form-harvesters
        [dataset]="dataset"
        [formState]="formState.harvesters">
      </app-dataset-form-harvesters>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-container
          [ngTemplateOutlet]="tabIcon"
          [ngTemplateOutletContext]="{
            type: (formState.attachments | async),
            default: 'attach_file',
          }">
        </ng-container>
        Files
      </ng-template>
      <app-dataset-form-attachment-list
        [dataset]="dataset"
        [disableDelete]="
          dataset.type !== 'draft' && (isAdmin$ | async) !== true
        "></app-dataset-form-attachment-list>
    </mat-tab>
  </mat-tab-group>
}

<ng-template #tabIcon let-type="type" let-default="default">
  @switch (type) {
    @case (FormStateType.Invalid) {
      <mat-icon class="npdc-error-icon">error</mat-icon>
    }
    @default {
      <mat-icon>{{ default }}</mat-icon>
    }
  }
</ng-template>
