import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AuthService, NotificationService } from '@services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
})
export class LoginComponent {
  public loginForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    private authService: AuthService,
    private notificationService: NotificationService,
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
  }

  public onSubmit() {
    this.loginForm.markAsPending();

    this.authService
      .logIn(this.loginForm.value.email, this.loginForm.value.password)
      .subscribe({
        next: () => {
          this.dialogRef.close(true);
        },
        error: (err) => {
          console.error('login', err);

          if (err.error?.details?.issues[0]?.type === 'LockedAccount') {
            this.notificationService.error('This account is locked!');
          } else {
            this.notificationService.error(
              'Invalid user credentials. Make sure that email and password are correct.',
            );
          }

          for (const control in this.loginForm.controls) {
            this.loginForm.controls[control].setErrors({ invalid: true });
          }
        },
      });
  }
}
