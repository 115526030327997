@if (!selection.hasValue()) {
  <button mat-flat-button (click)="openForm()">Add</button>
} @else {
  @if (selection.selected.length === 1) {
    <button mat-flat-button (click)="openForm(selection.selected[0])"
      >Edit</button
    >
  }
  <button mat-button (click)="onDelete()">Delete</button>
}

<mat-selection-list
  [multiple]="selection.isMultipleSelection()"
  (selectionChange)="onListSelectionChange($event)">
  @for (element of dataSource.data; track element; let isLast = $last) {
    <mat-list-option
      togglePosition="before"
      [value]="element"
      [selected]="selection.isSelected(element)">
      <span matListItemTitle>{{
        (element.userId | resolveUser | async)?.email
      }}</span>
      <span matListItemLine><b>Role</b>: {{ element | roleString }}</span>
      <span matListItemLine><b>ID</b>: {{ element.userId }}</span>
    </mat-list-option>
  }
</mat-selection-list>

<mat-paginator
  [pageSizeOptions]="[10, 20, 50, 100, 200]"
  (page)="loadPage($event, false)"
  showFirstLastButtons></mat-paginator>
