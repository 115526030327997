<p>
  @if (!selection.hasValue()) {
    <button mat-flat-button (click)="openForm()">Add</button>
  } @else {
    @if (selection.selected.length === 1) {
      <button mat-flat-button (click)="openForm(selection.selected[0])"
        >Edit</button
      >
    }
    <button mat-button (click)="onDelete()">Delete</button>
  }
</p>

<mat-selection-list
  [multiple]="selection.isMultipleSelection()"
  (selectionChange)="onListSelectionChange($event)">
  @for (element of dataSource.data; track element; let isLast = $last) {
    <mat-list-option
      togglePosition="before"
      [value]="element"
      [selected]="selection.isSelected(element)">
      <span matListItemTitle><b>Start</b>: {{ element.startDate }}</span>
      @if (element.endDate) {
        <span matListItemLine><b>End</b>: {{ element.endDate }}</span>
      }
    </mat-list-option>
  }
</mat-selection-list>
