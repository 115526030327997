import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormControlStatus, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  AutocompleteOption,
  AutocompleteOptionProvider,
  Dataset,
  DatasetContent,
  FormState,
  ScienceKeyword,
} from '@models';

import ScienceKeywords from '@data/science-keywords.json';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { DatasetService, NotificationService } from '@services';
import { MatInputModule } from '@angular/material/input';
import { FormControlMarkdownComponent } from '@components/form-control/markdown/markdown.component';
import { FormControlAutocompleteComponent } from '@components/form-control/autocomplete/autocomplete.component';

@Component({
  selector: 'app-dataset-form-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss'],
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    FormControlMarkdownComponent,
    FormControlAutocompleteComponent,
  ],
})
export class DatasetFormMetadataComponent implements OnInit, OnDestroy {
  @Input({ required: true })
  dataset!: Dataset;

  @Input({ required: true })
  titleFormState!: BehaviorSubject<FormState>;

  @Input({ required: true })
  summaryFormState!: BehaviorSubject<FormState>;

  @Input({ required: true })
  keywordsFormState!: BehaviorSubject<FormState>;

  public autocompleteProvider: AutocompleteOptionProvider<ScienceKeyword>;

  private subscriptions: Subscription[];
  private options: AutocompleteOption<ScienceKeyword>[];

  public title: FormControl;
  public summary: FormControl;
  public keywords: FormControl;

  constructor(
    private datasetService: DatasetService,
    private notificationService: NotificationService,
  ) {
    this.subscriptions = [];
    this.title = new FormControl('', {
      validators: [Validators.required, Validators.maxLength(1024)],
      updateOn: 'blur',
    });
    this.summary = new FormControl('', [
      Validators.maxLength(20000),
      Validators.required,
    ]);
    this.keywords = new FormControl([], [Validators.required]);

    this.options = (ScienceKeywords as ScienceKeyword[]).map(
      this.asAutocompleteOption,
    );
    this.autocompleteProvider = (term: string) => {
      return of(
        this.options.filter((option: AutocompleteOption<ScienceKeyword>) => {
          return option.id.toLowerCase().includes(term.toLocaleLowerCase());
        }),
      );
    };

    this.subscriptions.push(
      this.title.valueChanges.subscribe(() => {
        if (this.title.pristine || !this.title.valid) {
          return;
        }

        const datasetContent = {
          ...this.dataset.content,
          title: this.title.value,
        };

        this.updateDataset(datasetContent);
      }),
    );

    this.subscriptions.push(
      this.summary.valueChanges.subscribe(() => {
        if (this.summary.pristine) {
          return;
        }

        const datasetContent = {
          ...this.dataset.content,
        };

        if (this.summary.value?.length) {
          datasetContent.summary = this.summary.value;
        } else {
          delete datasetContent.summary;
        }

        this.updateDataset(datasetContent);
      }),
    );

    this.subscriptions.push(
      this.keywords.valueChanges.subscribe(() => {
        if (this.keywords.pristine) {
          return;
        }

        const datasetContent = {
          ...this.dataset.content,
        };

        if (this.keywords.value?.length) {
          datasetContent.keywords = this.keywords.value.map(
            (keywordOption: AutocompleteOption<ScienceKeyword>) => {
              return keywordOption.value;
            },
          );
        } else {
          delete datasetContent.keywords;
        }

        this.updateDataset(datasetContent);
      }),
    );
  }

  ngOnInit() {
    this.title.setValue(this.dataset.content.title);
    this.titleFormState.next(FormState.Valid);

    if (this.dataset.content.summary) {
      this.summary.setValue(this.dataset.content.summary);
      this.summaryFormState.next(FormState.Valid);
    } else {
      this.summaryFormState.next(FormState.Invalid);
      this.summary.markAllAsTouched();
    }

    if (this.dataset.content.keywords?.length) {
      this.keywords.setValue(
        this.dataset.content.keywords?.map(this.asAutocompleteOption),
      );
      this.keywordsFormState.next(FormState.Valid);
    } else {
      this.keywordsFormState.next(FormState.Invalid);
      this.keywords.markAllAsTouched();
    }

    this.subscriptions.push(
      this.title.statusChanges.subscribe((status: FormControlStatus) => {
        if (status === 'INVALID') {
          this.titleFormState.next(FormState.Invalid);
        } else {
          this.titleFormState.next(FormState.Valid);
        }
      }),
    );

    this.subscriptions.push(
      this.summary.statusChanges.subscribe((status: FormControlStatus) => {
        if (status === 'INVALID') {
          this.summaryFormState.next(FormState.Invalid);
        } else {
          this.summaryFormState.next(FormState.Valid);
        }
      }),
    );

    this.subscriptions.push(
      this.keywords.statusChanges.subscribe((status: FormControlStatus) => {
        if (status === 'INVALID') {
          this.keywordsFormState.next(FormState.Invalid);
        } else {
          this.keywordsFormState.next(FormState.Valid);
        }
      }),
    );
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private asAutocompleteOption(
    keyword: ScienceKeyword,
  ): AutocompleteOption<ScienceKeyword> {
    const objectValues = Object.values(keyword) as string[];
    const id = objectValues.join(' > ');
    const displayValue = objectValues[objectValues.length - 1];

    return {
      id: id,
      value: keyword,
      displayValue: displayValue,
    } as AutocompleteOption<ScienceKeyword>;
  }

  private updateDataset(datasetContent: DatasetContent) {
    this.datasetService
      .updateDataset(this.dataset.id, datasetContent)
      .subscribe({
        next: () => {
          this.dataset.content = datasetContent;
        },
        error: (err) => {
          console.error(err);
          this.notificationService.error('Failed to save changes.');
        },
      });
  }
}
