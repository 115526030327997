@if (!isEdit) {
  <h2 mat-dialog-title>Add link</h2>
} @else {
  <h2 mat-dialog-title>Edit link</h2>
}

<mat-dialog-content>
  <form id="link-form" [formGroup]="linkForm" (ngSubmit)="onSubmit()">
    <p>
      <mat-form-field appearance="outline">
        <mat-label>URL</mat-label>
        <mat-hint
          >URLs must include the protocol (ex: https://example.com)</mat-hint
        >
        <input matInput formControlName="href" />
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="outline">
        <mat-label>Type</mat-label>
        <mat-select formControlName="rel">
          <mat-option value="parent">Parent</mat-option>
          <mat-option value="project">Project</mat-option>
          <mat-option value="publication">Publication</mat-option>
          <mat-option value="related">Related</mat-option>
          <mat-option value="service">Service</mat-option>
        </mat-select>
        <mat-hint>Choose 'Related' when in doubt</mat-hint>
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="outline">
        <mat-label>Title</mat-label>
        <input matInput formControlName="title" autocomplete="off" />
      </mat-form-field>
    </p>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-flat-button
    type="submit"
    form="link-form"
    [disabled]="linkForm.pristine || !linkForm.valid">
    Save
  </button>
  <button mat-button type="button" (click)="onCancel()"> Cancel </button>
</mat-dialog-actions>
