import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, NotificationService } from '@services';
import { PasswordValidator } from '@validators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-account-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class AccountPasswordComponent implements OnInit, OnDestroy {
  public form: FormGroup;

  private subscriptions: Subscription[];
  private token: string | null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
  ) {
    this.subscriptions = [];
    this.token = null;
    this.form = new FormGroup(
      {
        password: new FormControl('', [PasswordValidator]),
        confirmPassword: new FormControl('', [PasswordValidator]),
      },
      this.passwordMatchValidator,
    );
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.queryParamMap.subscribe((params) => {
        const token = params.get('token');
        if (!token) {
          this.router.navigate(['/']);
        }

        this.token = token!;
      }),
    );
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  public onSubmit(): void {
    if (!this.token) {
      return;
    }

    this.form.markAsPending();

    this.authService
      .confirmPasswordRecovery(this.token, this.form.value.password)
      .subscribe({
        next: () => {
          this.notificationService.success('Password changed successfully.');
          this.router.navigate(['/login']);
        },
        error: (err) => {
          console.error(err);
          this.notificationService.error('Failed to change password.');
        },
      });
  }

  private passwordMatchValidator(
    control: AbstractControl<FormGroup>,
  ): ValidationErrors | null {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');

    if (password?.value !== confirmPassword?.value) {
      const err = { passwordMismatch: true };
      if (confirmPassword) {
        confirmPassword.setErrors(err);
      }

      return err;
    }

    return null;
  }
}
