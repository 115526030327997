<h2 mat-dialog-title>Delete</h2>

<mat-dialog-content>
  @if (isLoading()) {
    <p>Files are being deleted. Please wait...</p>
  } @else {
    <p
      >Are you sure you want to delete the selected file(s)? This action cannot
      be undone.</p
    >
  }
</mat-dialog-content>

<mat-dialog-actions align="end">
  @if (isLoading()) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  } @else {
    <button mat-flat-button type="button" (click)="submit()">Delete</button>
    <button mat-button type="button" [mat-dialog-close]="true">Cancel</button>
  }
</mat-dialog-actions>
