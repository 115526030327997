import { Pipe, PipeTransform } from '@angular/core';
import { Permission } from '@models';

// transforms permissions into a human readable role name
@Pipe({
  name: 'roleString',
})
export class RoleStringPipe implements PipeTransform {
  transform(permission: Permission): string {
    if (permission?.mayDelete) {
      return 'Owner';
    }

    if (permission?.mayUpdate) {
      return 'Contributor';
    }

    return 'Reader';
  }
}
