<p>
  <mat-card class="npdc-leaflet" appearance="outlined">
    <app-leaflet-group
      [geometry]="dataset.content.geojson"
      [readonly]="false"
      (geometryChange)="onChange($event)"></app-leaflet-group>
  </mat-card>
</p>

<p>
  Switch between map projections and use the interactive map controls to mark
  areas of relevance. Changes will be saved automatically.
</p>

<p>The <b>T</b> tool can be used to add precise geometries.</p>
