import { Component, inject, Inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Attachment } from '@models';
import { AttachmentService, NotificationService } from '@services';

@Component({
  selector: 'app-attachment-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
  imports: [
    MatDialogModule,
    MatListModule,
    MatButtonModule,
    MatProgressBarModule,
    MatIconModule,
  ],
})
export class AttachmentDeleteComponent {
  attachmentService = inject(AttachmentService);
  notificationService = inject(NotificationService);

  isLoading = signal(false);

  constructor(
    public dialogRef: MatDialogRef<AttachmentDeleteComponent>,
    @Inject(MAT_DIALOG_DATA)
    private attachments: Attachment[],
  ) { }

  public async submit() {
    this.isLoading.set(true);

    const deletedAttachments = [];
    for (const attachment of this.attachments) {
      try {
        await this.attachmentService.deleteAttachment(attachment.datasetId, attachment.id);
        deletedAttachments.push(attachment);
      } catch (err) {
        console.error(err);
      }
    }

    if (deletedAttachments.length != this.attachments.length) {
      this.notificationService.error("Failed to delete one or multiple files.");
    }

    this.dialogRef.close(deletedAttachments);
  }
}
