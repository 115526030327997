import { CommonModule } from '@angular/common';
import { Component, computed, input, output } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ProjectionCode } from '@models/leaflet';
import geojson from 'geojson';
import { LeafletComponent } from '../leaflet.component';

@Component({
  selector: 'app-leaflet-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
  imports: [
    CommonModule,
    MatTabsModule,
    LeafletComponent,
  ],
})
export class LeafletGroupComponent {
  readonly projectionLabels = new Map([
    [ProjectionCode.Arctic, 'EPSG3575 - North Pole LAEA Europe'],
    [ProjectionCode.Mercator, 'EPSG3857 - Pseudo-Mercator'],
    [ProjectionCode.Antarctic, 'EPSG3031 - Antarctic Polar Stereographic'],
  ]);

  geometry = input<geojson.FeatureCollection>();
  readonly = input(true);
  geometryChange = output<geojson.FeatureCollection>();

  featuresCount = computed(() => {
    const result = {
      [ProjectionCode.Arctic]: 0,
      [ProjectionCode.Mercator]: 0,
      [ProjectionCode.Antarctic]: 0,
    };

    const geometry = this.geometry();
    if (!geometry?.features) {
      return result;
    }

    for (const feature of geometry.features) {
      const code =
        (feature.properties?.['projection'] as ProjectionCode) ||
        ProjectionCode.Mercator;
      result[code] += 1;
    }

    return result;
  });

  projections = computed(() => {
    const allProjections = Array.from(this.projectionLabels.keys());
    if (!this.readonly()) {
      return allProjections;
    }

    const geometry = this.geometry();
    if (!geometry?.features) {
      return [];
    }

    const projections = [];
    for (const projection of allProjections) {
      if (this.featuresCount()[projection] == 0) {
        continue;
      }

      projections.push(projection);
    }

    return projections;
  });
}
