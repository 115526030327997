<p>
  Selecting harvesters will flag the dataset for exposure in the associated
  thematic data portals. For exposure through search engines and generic data
  portals no further action is required.
</p>

<form class="form" [formGroup]="form">
  @for (h of harvesterfields; track h) {
    <div>
      <mat-checkbox
        [name]="h.data.set"
        [checked]="h.checked"
        [value]="h.value"
        (change)="onChange($event)">
        {{ h.label }}
      </mat-checkbox>
    </div>
  }
</form>
